import React, { useState, useEffect } from 'react'; 
import { View, StyleSheet, Dimensions, Pressable  } from 'react-native'; 
import { useSelector, useDispatch } from 'react-redux'
import { setClientConfiguration, parameters, userParams } from '../reducers/parameterSlice';
import { useIsMounted } from '../utilities/System';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import { Progress, SuccessPage, ErrorPage, ProgressDialog, ErrorDialog, SuccessDialog  } from '../components/DialogComponents';
import { IconButton, Typography, Rating } from '@mui/material'; 
import { Label, TextField, PushButton } from '../components/FormComponents';
import { executeGet, executePost, api, resCode } from '../utilities/Network';


const DetailPage = (props)=>{
    const isMounted = useIsMounted(); 
    const clientParams = useSelector(parameters); 
    const [startFetch, setStartFetch] = useState(props.open); 
    const [progressState, setProgressState ] = useState({
        showProgress : true, 
        showError : false, 
        showSuccess : false, 
        message : "Memuat Data"
    })
    const [rateValue, setRateValue] = useState(0); 
    const [bookDetails, setBookDetails ] = useState({
        loaded : false
    });

    useEffect(()=>{
        if(!bookDetails.loaded){
            fetchData(); 
        }
    },[bookDetails.loaded])

    const fetchData = async()=>{
        let formData = new FormData();
        formData.append("id_konten", props.route.params.id_konten); 
        formData.append("encrypted", "0");

        const response = await executePost(api.detailContent, formData); 
        let progress = { ...progressState}
        if(response.code == resCode.ok){
            progress.showProgress = false; 
            setBookDetails(response.data); 
            setProgressState(progress); 
            fetchRate();
            //console.log(JSON.stringify(response));

        } else {
            if( response.code != resCode.error){
                //ANCHOR - Error Page
                progress.showProgress = false; 
                progress.message = response.msg; 
                progress.showError = true; 
                setProgressState(progress); 
            } else {
                //ANCHOR - Force Logout
                window.postMessage({command : "FORCE_LOGOUT"}, "*");
            }
        }

    }

    const fetchRate = async()=>{
        let url = api.checkRate + props.route.params.id_konten; 
        const response = await executeGet(url); 
        if(response.code == resCode.ok){
            if(isMounted()){
                setRateValue(parseInt(response.rate));
            }
        }
    }

    const onReadClick = ()=>{
        //TODO - On read click
        //props.navigation.navigate("Reader", bookDetails)
        let _progressState = { ...progressState }
        _progressState.showProgress = true; 
        _progressState.message = "Autentikasi Baca";
        setProgressState(_progressState); 
        readRequest(); 
    }

    const onBorrowClick = ()=>{
        //TODO - On Borrow Click 
        let _progressState = { ...progressState }
        _progressState.showProgress = true; 
        _progressState.message = "Meminjam Buku"; 
        setProgressState(_progressState)
        borrowRequest(); 
    }

    const borrowRequest = async()=>{
        let formData = new FormData(); 
        formData.append("id_konten", props.route.params.id_konten)
        const response = await executePost(api.borrowBook, formData); 
        let _progressState = {...progressState }
        if(response.code == resCode.ok){
            _progressState.showProgress = false; 
            _progressState.message = "Peminjaman berhasil. Buku dapat dibaca melalui menu \"Rak Pinjam\"";
            _progressState.showSuccess = true;
            if(isMounted()){
                setProgressState(_progressState); 
            }
        } else {
            _progressState.showProgress = false;  
            if(response.code != resCode.error){
                _progressState.message = response.msg; 
                _progressState.showError = true; 
                if(isMounted()){
                    setProgressState(_progressState); 
                }
            } else {
                //ANCHOR - Force Logout
                window.postMessage({command : "FORCE_LOGOUT"}, "*");
            }
        }
    }


    const readRequest = async()=>{
        let formData = new FormData(); 
        let _progressState = { ...progressState }
        formData.append("id_konten", props.route.params.id_konten); 
        const response = await executePost(api.requestBaca, formData); 
        //console.log("REQUEST BACA");
        //console.log(JSON.stringify(response));
        if(response.code == resCode.ok){
            _progressState.showProgress = false; 
            if(isMounted()){
                setProgressState(_progressState); 
                let data = {
                    book : bookDetails, 
                    read : response,
                    readMode : true 
                }
                props.navigation.navigate("Reader", data); 
            }

        } else {
            if(!response.code != resCode.error){
                if(isMounted()){
                    _progressState.showProgress = false;
                    _progressState.showError = true ; 
                    _progressState.message = response.msg; 
                    setProgressState(_progressState); 
                }

            } else {
                //ANCHOR - Force Logout 
                window.postMessage({command : "FORCE_LOGOUT"}, "*");

            }
        }
    }

    return(
        <View style={{flex : 1}}>
            {
                progressState.showProgress && 
                <Progress 
                    text={ progressState.message }
                />
            }
            {
                progressState.showSuccess && 
                <SuccessPage 
                    text={ progressState.message }
                    onClick={()=>{ props.navigation.goBack()} }
                />
            }
            {
                progressState.showError && 
                <ErrorPage 
                    text={ progressState.message }
                    onClick={()=>{ props.navigation.goBack()}}
                />
            }
            {
                (!progressState.showProgress && !progressState.showError && !progressState.showSuccess ) &&
                <View style={{flex : 1 }}>
                    <View style={{
                        backgroundColor : clientParams.warna_header, 
                        height : 48, 
                        flexDirection : "row",
                        alignItems : "center",
                        paddingLeft : 12, 
                        paddingRight : 12
                    }}>
                        <IconButton
                            onClick={()=>{ props.navigation.goBack()}}
                        >
                            <CloseIcon 
                                sx={{ color : "white"}}
                            />
                        </IconButton>
                        <Label 
                            size={14}
                            weight={"600"}
                            color={"white"} 
                            style={{ marginLeft : 16 }}
                            text={"Detail Buku"}
                        />
                    </View>
                    <View style={{flex : 1, flexDirection : "row", marginTop : 16 }}>
                        <View
                            style={{
                                marginLeft : 24
                            }}
                        >
                            <img
                                src={ bookDetails.cover_file} 
                                style={{
                                    borderRadius : 12, 
                                    height : 250,

                                }}
                            />
                            {
                                bookDetails.stok > 0 && 
                                <>
                                    <PushButton
                                        variant={"contained"}
                                        text={"Baca"} 
                                        textSize={14}
                                        style={{
                                            marginTop : 12 
                                        }}
                                        onClick={ onReadClick }
                                    />
                                    <PushButton
                                        variant={"outlined"} 
                                        text={"Pinjam"}
                                        style={{marginTop : 8}} 
                                        textColor={ clientParams.warna_header }
                                        onClick={ onBorrowClick }
                                    />

                                </>
                            }



                        </View>
                        <View style={{flex : 1, marginTop : 8, marginLeft : 16, marginRight : 16 }}>
                            <Label 
                                size={16}
                                text={ bookDetails.judul}
                                weight={"600"}
                            />
                            <Pressable
                                style={{
                                    cursor : "pointer"
                                }}
                                
                            >
                                <Rating
                                    value={ rateValue }
                                    readOnly
                                    size={"small"}
                                />
                            </Pressable>
                            <Label
                                size={14}
                                color={ clientParams.warna_header }
                                text={ bookDetails.penulis } 
                                weight={"600"}
                                style={{marginTop : 8 }}
                            />
                            <Label 
                                size={14}
                                text={ bookDetails.penerbit }
                                weight={"400"}
                                style={{marginTop : 8 }}
                            />
                            <View style={{
                                flexDirection : "row", 
                                alignItems : "center", 
                                marginTop : 12, 
                                marginBottom : 12, 
                                padding : 4, 
                                width : "10%", 
                                borderRadius : 8, 
                                borderWidth : 1, 
                                borderColor : clientParams.warna_header,
                                justifyContent : "center"
                            }}>
                                <Label 
                                    size={14}
                                    color={ clientParams.warna_header}
                                    text={"Stok : " + bookDetails.stok + "/" + bookDetails.qty }
                                />
                            </View>
                            <Typography
                                fontFamily={"Poppins"}
                                fontSize={14}
                                fontWeight={"400"}
                                component={"span"}
                                style={{ 
                                    overflow : "scroll", 
                                    marginBottom : 12
                                }}
                            >
                                <div
                                    dangerouslySetInnerHTML={{ __html: bookDetails.sinopsis}}
                                />
                            </Typography>


                        </View>
                    </View>
                </View>
            }



        </View>
    )

}



export { DetailPage }