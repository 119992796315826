import React,{ useCallback, useEffect, useRef } from 'react';
import { Platform } from 'react-native';
import  AsyncStorage  from '@react-native-async-storage/async-storage';
import uuid from 'react-native-uuid';
import crypto from 'crypto'
const algorithm = 'aes-256-ctr';
const iv = crypto.randomBytes(16);
const secretKey = '6QwsU24ZfQVeknZueP5mBLR3H7iKTa9B';
var clientCode ; 

const encryptText = (text) => {
    
    const cipher = crypto.createCipheriv(algorithm, secretKey, iv);
    const encrypted = Buffer.concat([cipher.update(text), cipher.final()]);
    return {
        iv: iv.toString('hex'),
        content: encrypted.toString('hex')
    }
}


const decryptText = (hash) => {
    const decipher = crypto.createDecipheriv(algorithm, secretKey, Buffer.from(hash.iv, 'hex'));
    const decrpyted = Buffer.concat([decipher.update(Buffer.from(hash.content, 'hex')), decipher.final()]);
    return decrpyted.toString();
}

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


const createHeaders = async(parameters)=>{
    //Check for local params
    try {
        clientCode = parameters.code;
        let localStorageKey = parameters.code; 
        
        const value = await AsyncStorage.getItem(localStorageKey);
        if(value == null) {
            //console.log("VALUE IS NULL");
            let _uuid = uuid.v4(); 
            if(parameters.hasOwnProperty("uuid")){
                _uuid = parameters.uuid; 
            }
            global.headers = {
                OS : parameters.os,  
                VERSIONCODE : parameters.version, 
                CLIENTID : parameters.clientId,  
                UUID : _uuid
            }
            await AsyncStorage.setItem(localStorageKey, JSON.stringify(global.headers));
        } else {
            let headers = JSON.parse(value); 
            global.headers = headers;
        }
    }  catch(error){
        global.headers = {
            OS : parameters.os,  
            VERSIONCODE : parameters.version, 
            CLIENTID : parameters.clientId,  
            UUID : uuid.v4()
        }
    }
}

const saveHeaders = async(parameters)=>{
    try {
        let localStorageKey = clientCode;
        global.headers = parameters;
        await AsyncStorage.setItem(localStorageKey, JSON.stringify(parameters)); 
    } catch(error){
        console.error(error.message);
    }

}

const resetHeaders = async(parameters)=>{
    await AsyncStorage.removeItem(clientCode); 
    await createHeaders(parameters); 
}

const dataURLToFile = (uri, filename)=>{
    var arr = uri.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = window.atob(arr[1]), 
    n = bstr.length, 
    u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type:mime});
}

const shuffleArray = (array)=>{
    for(var i = array.length - 1 ; i > 0;i--){
        var j = Math.floor(Math.random() * (i + 1)); 
        var temp = array[i]; 
        array[i] = array[j];
        array[j] = temp;
        
    }
    let data = array;
    return data;

}

const useIsMounted = ()=>{
    const isMountedRef = useRef(true);
    const isMounted = useCallback(() => isMountedRef.current, []);
    useEffect(() => {
      return () => void (isMountedRef.current = false);
    }, []);
    return isMounted;
}

const randomId = (length)=>{
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

const romanize = (num)=>{
    if (isNaN(num))
        return NaN;
    var digits = String(+num).split(""),
        key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
               "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
               "","I","II","III","IV","V","VI","VII","VIII","IX"],
        roman = "",
        i = 3;
    while (i--)
        roman = (key[+digits.pop() + (i * 10)] || "") + roman;
    return Array(+digits.join("") + 1).join("M") + roman;
}

export { encryptText, decryptText, capitalizeFirstLetter, createHeaders, romanize,
    saveHeaders, resetHeaders, dataURLToFile, shuffleArray, useIsMounted, randomId }