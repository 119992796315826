import React, { Component, useState, useEffect } from 'react'; 
import { View, StyleSheet } from 'react-native';
import { Label } from '../components/FormComponents'; 

const Splash = (props)=>{
    const [valid, setValid ] = useState(true); 
    useEffect(()=>{
            
        console.log("MOUNTED");
        
    })

    return(
        <View style={ styles.container }>
            <Label 
                size={14}
                text={"Hello World"}
                weight={"400"} 
            />
        </View>
    )
}

const styles = StyleSheet.create({
    container : {
        flex : 1, 
        alignItems : "center", 
        justifyContent : "center",
        backgroundColor : "white"
    }
});

export { Splash }