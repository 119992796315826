import React, { useState, useEffect, useRef } from 'react'; 
import { View, StyleSheet } from 'react-native'; 
import { Label, TextField, PushButton } from '../components/FormComponents'; 
import { Progress, ProgressDialog, ErrorDialog, SuccessDialog } from '../components/DialogComponents';
import { executeGet, executePost, api, resCode } from '../utilities/Network';
import { createHeaders, saveHeaders } from '../utilities/System';
import { useSelector, useDispatch } from 'react-redux'
import { setClientConfiguration,setHeaderConfiguration, parameters, setUserParams } from '../reducers/parameterSlice';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { ForgotPasswordDialog  } from '../components/DialogComponents';


//== LOGIN DESKTOP ==//
const Login = (props)=>{

    const clientParams = useSelector(parameters); 
    const dispatch = useDispatch(); 

    const emailRef = useRef(); 
    const passwordRef = useRef(); 

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [email, setEmail ] = useState("");
    const [errorEmail, setErrorEmail ] = useState(false);
    const [password, setPassword ] = useState("");
    const [errorPassword, setErrorPassword ] = useState(false);
    const [showForgotPasswordDialog, setShowForgotPasswordDialog ] = useState(false);
    const initProgress = {
        showProgress : false, 
        message : "Harap Tunggu", 
        showError : false, 
        showSuccess : false
    }
    const [progressParams, setProgressParams]= useState(initProgress)
 
    useEffect(()=>{
        window.electron.receiveMessage("RECEIVE_READ_TOKEN", (params)=>{
            let token = params.token; 
            let _progress = { ...progressParams}  
            _progress.showProgress = true; 
            setProgressParams(_progress); 
            autoLogin(token);

        })

    })

    //NOTE - Verify App Token 
    const autoLogin = async(token)=>{
        const url = api.autoLogin + token;
        const response = await executeGet(url); 
        let _initProgress = { ...progressParams}
        _initProgress.showProgress = false; 
        setProgressParams(_initProgress); 

       
        if(response.code == 200){
            let data = response.data; 
            global.headers.UUID = data.uuid; 
            global.headers.KEY = data.token; 
            global.headers.USERNAME = data.username; 
            global.headers.USERLEVEL = data.level; 
            global.headers.USERID = data.id_user;
            await saveHeaders(global.headers);

            if(data.hasOwnProperty("id_konten")){
                //alert("SENDING ID KONTEN: " + data.id_konten);
                window.electron.sendMessage("SEND_ID_KONTEN", { id_konten : data.id_konten}); 
            }
            _initProgress.showProgress = true; 
            setProgressParams(_initProgress); 
            relogin(); 


        }

    }

    const relogin = async()=>{
        let formData = new FormData(); 
        formData.append("id_user", global.headers.USERID) 
        const response = await executePost(api.credentials, formData); 
        //console.log("RESPONSE: ", response.code);
        let _initProgress = { ...progressParams}
        _initProgress.showProgress = false; 
        setProgressParams(_initProgress); 
      
        if(response.code == resCode.ok){
            //console.log(JSON.stringify(clientParams)); 
           
            let newHeaders = {
                CLIENTID : global.headers.CLIENTID, 
                UUID : global.headers.UUID,  
                OS : global.headers.OS, 
                VERSIONCODE : global.headers.VERSIONCODE, 
                KEY : response.token, 
                USERNAME : response.data.username, 
                USERID : response.data.id_user,
                USERLEVEL : response.data.level, 
                MAXDAY : clientParams.max_hari, 
                MAXCONTENT : clientParams.max_konten
            }
            let userParams = {
                nama : response.data.nama, 
                url_foto : response.data.url_foto,
                troli_donasi : (response.data.hasOwnProperty("troli_donasi") ? response.data.troli_donasi : "0"),
                troli_toko : (response.data.hasOwnProperty("troli_toko") ? response.data.troli_toko : "0"), 
                profile_complete : response.data.profile_complete
            }
            dispatch(setUserParams(userParams)); 
            //setHeaderConfiguration(newHeaders); 
            saveHeaders(newHeaders); 
            setTimeout(()=>{
                props.navigation.reset({
                    index : 0, 
                    routes :[{ name : "Main"}]
                })
            },1000)
            
        }

    }
    
    //ANCHOR - Login Click 
    const onLoginClick = ()=>{
        
        if(email.length < 5 ) {
            setErrorEmail(true);
            return;
        }

        if(password.length < 5 ) {
            setErrorPassword(true);
            return;
        }

        validateInput();

    }

    //ANCHOR - Confirm Click 
    const resetPassword = (email)=>{
        setShowForgotPasswordDialog(false);
        let formData = new FormData(); 
        formData.append("username", email);
        
        //Tampilkan Progress dialog 
        let params = { ...progressParams }; 
        params.showProgress = true;
        params.message = "Harap Tunggu";
        setProgressParams(params);
        //Eksekusi Reset 
        executeReset(formData);

    }
    //ANCHOR - Execute reset password 
    const executeReset = async(formData)=>{
        const response = await executePost(api.forgotPassword, formData); 
        let params = {...progressParams}; 

        //Sembunyikan progress dialog 
        params.showProgress = false;
        if(response.code == resCode.ok){
            params.showSuccess = true; 
            params.message = response.msg; 
            setProgressParams(params);
        } else {
            params.showError = true; 
            params.message = response.msg; 
            setProgressParams(params); 
        }
    }

    //ANCHOR - On Email Press 
    const onEmailPress = (event)=>{
        if(event.key == "Enter"){
            passwordRef.current.focus();
        }
    }
    //ANCHOR - On Password Press 
    const onPasswordPress = (event)=>{
        if(event.key == "Enter"){
            validateInput();
        }
    }

    //ANCHOR - Validate Input 
    const validateInput = ()=>{
        emailRef.current.focus(); 
        if(email == "" || email.length < 5) {
            setErrorEmail(true);
            emailRef.current.focus();
            return;

        }

        if(password == "" || password.length < 5 ) {
            setErrorPassword(true);
            passwordRef.current.focus(); 
            return;

        }

        //Tampilkan Progress Dialog 
        let params = {...progressParams}; 
        params.showProgress = true; 
        
        params.message = "Autentikasi";
        setProgressParams(params);
         
        let formData = new FormData(); 
        formData.append("user", email);
        formData.append("pass", password); 
        executeLogin(formData);
    }

    //ANCHOR - Execute Login 
    const executeLogin = async(formData)=>{
        const response = await executePost(api.login, formData); 
        let params = { ...progressParams}
        params.showProgress = false; 
        //console.log(JSON.stringify(response));
        if(response.code == resCode.ok){
            setProgressParams(params); 
            //console.log(JSON.stringify(response));
            
            let newHeaders = {
                CLIENTID : global.headers.CLIENTID, 
                UUID : global.headers.UUID,  
                OS : global.headers.OS, 
                VERSIONCODE : global.headers.VERSIONCODE, 
                KEY : response.token, 
                USERNAME : response.data.username, 
                USERID : response.data.id_user,
                USERLEVEL : response.data.level, 
                MAXDAY : clientParams.max_hari, 
                MAXCONTENT : clientParams.max_konten
            }

            window.electron.sendMessage("SET-USERNAME", {username : response.data.username}); 
            setHeaderConfiguration(newHeaders); 
            saveHeaders(newHeaders); 
            let userParams = {
                nama : response.data.nama, 
                foto : response.data.url_foto,
                troli_donasi : (response.data.hasOwnProperty("troli_donasi") ? response.data.troli_donasi : "0"),
                troli_toko : (response.data.hasOwnProperty("troli_toko") ?  response.data.troli_toko : "0" ), 
                profile_complete : response.data.profile_complete
            }
            dispatch(setUserParams(userParams)); 
            //console.log(JSON.stringify(clientParams ));
            props.navigation.reset({
                index : 0, 
                routes :[{ name : "Main"}]
            })
        } else {
            params.showError = true; 
            params.message = response.msg; 
            setProgressParams(params);
        }
    }
    return(
        <View style={ styles.container} tabIndex={0}>
            {
                //LEFT SIDE 
            }
            <View style={ styles.leftSide}>
                <img
                    src={ clientParams.url_logo_login}
                    style={{
                        width : 450, 
                        height : "auto"
                    }}
                />
            </View>
            <View style={ styles.rightSide}>
                <Label 
                    weight={"600"}
                    size={16}
                    text={"Masuk ke akun anda"}
                    style={{
                        marginTop : 16,  
                        marginLeft : 16, 
                        marginBottom : 16
                    }}
                />
                <View 
                    style={{
                        height : 0.5,
                        backgroundColor : "silver",
                        width : "100%",
                        marginBottom : 16
                    }}
                />  
                <TextField 
                    ref={ emailRef }
                    labelText="ID"
                    marginLeft={16}
                    marginRight={16}
                    onChange={(text)=>{ 
                        setEmail(text);
                        setErrorEmail(false);
                    }}
                    placeholder={"Masukkan ID pengguna"}
                    error={ errorEmail }
                    errorText={"Harap masukkan ID anda"}
                    onKeyPress={ onEmailPress }
                />
                <TextField 
                    ref={ passwordRef }
                    labelText="Password"
                    marginTop={16}
                    marginLeft={16}
                    marginRight={16}
                    onChange={(text)=>{ 
                        setPassword(text);
                        setErrorPassword(false);
                    }}
                    placeholder={"Masukkan password"}
                    type={
                        passwordVisible ? "text" : "password"
                    }
                    withIcon={ true }
                    icon={
                        passwordVisible ? <VisibilityOffOutlinedIcon/> : <VisibilityOutlinedIcon/>
                    }
                    onIconClick={()=>{
                        passwordVisible ? setPasswordVisible(false) : setPasswordVisible(true)
                    }}
                    error={ errorPassword }
                    errorText={"Harap masukkan password anda"}
                    onKeyPress={ onPasswordPress }
                />
                <View style={{flexDirection : "row-reverse", 
                    marginTop : 12 , 
                    marginRight : 16,
                    marginBottom : 16
                    }}>
                    <Label 
                        weight={"600"}
                        size={12}
                        color={"#2980b9"}
                        text={"Lupa Password?"}
                        style={{
                            cursor : "pointer"
                        }}
                        onClick={()=>{ 
                            setShowForgotPasswordDialog(true)
                        }}
                    />
                </View>
                <PushButton 
                    text={"Login"} 
                    textWeight={"400"}
                    variant="contained"
                    style={{
                        marginLeft : 16, 
                        marginRight : 16,
                        borderRadius : 8
                    }}
                    onClick={ onLoginClick }
                />
                {
                    clientParams.url_sso == "0" && 
                    <View style={{
                        marginTop : 16, 
                        marginLeft : 16, 
                        marginRight : 16, 
                        flexDirection : "row", 
                        alignItems : "center",
                        justifyContent : "center"
                    }}>
                        <Label
                            size={12}
                            style={{marginRight : 4 }}
                            weight={"600"}
                            text={"Belum punya akun?"}
                        />
                        <Label
                            size={12}
                            style={{marginRight : 4, cursor : "pointer" }}
                            weight={"600"}
                            color={ clientParams.warna_header }
                            text={"daftar disini"}
                            onClick={()=>{ props.navigation.navigate("SignUp")}}
                        />
                    </View>

                }
                
            </View>

            <ForgotPasswordDialog
                open={ showForgotPasswordDialog }
                onCancelClick={()=>{
                    setShowForgotPasswordDialog(false)
                }}
                onConfirmClick={ resetPassword }
            />
            <ProgressDialog 
                open={ progressParams.showProgress }
                message={ progressParams.message }
            />
            <ErrorDialog 
                open={ progressParams.showError}
                message={ progressParams.message }
                label={"OK"}
                onClick={()=>{
                    let params = { ...progressParams};
                    params.showError = false;
                    setProgressParams(params);
                }}
            />
            <SuccessDialog
                open={ progressParams.showSuccess }
                message={ progressParams.message }
                label={"OK"}
                onClick={()=>{
                    let params = { ...progressParams}; 
                    params.showSuccess = false; 
                    setProgressParams(params); 
                }}
            />
        </View>
    )
}


const styles = StyleSheet.create({
    container : {
        flex : 1,
        backgroundColor : "white",
        flexDirection : "row"
    },
    leftSide : {
        flex : 0.7, 
        borderRightWidth : 0.3, 
        borderColor : "silver",
        alignItems : "center", 
        justifyContent : "center"
    },
    rightSide : {
        flex : 0.3, 
        backgroundColor : "white"
    }
})

export { Login }