import React, { useState, useEffect, useRef } from 'react'; 
import { View, StyleSheet, Dimensions, TouchableHighlight, TextInput, Pressable, ScrollView } from 'react-native'; 
import { executeGet, executePost, api, resCode } from '../utilities/Network';
import { useIsMounted, randomId, romanize } from '../utilities/System';
import { useSelector, useDispatch } from 'react-redux'
import {  parameters } from '../reducers/parameterSlice';

import { Progress, ProgressDialog, ErrorPage, ErrorDialog, SuccessDialog, CopyTextOptDialog, BookInfoDialog } from '../components/DialogComponents';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IconButton, Paper, Drawer, Box } from '@mui/material';
import { Label, TextField } from '../components/FormComponents';
import SearchIcon from '@mui/icons-material/Search';

import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';

import FirstPageOutlinedIcon from '@mui/icons-material/FirstPageOutlined';
import LastPageOutlinedIcon from '@mui/icons-material/LastPageOutlined';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';

import ZoomOutOutlinedIcon from '@mui/icons-material/ZoomOutOutlined';
import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined';
import PanToolOutlinedIcon from '@mui/icons-material/PanToolOutlined';

import { IconHand, IconText, IconMessage, IconMenu, IconDelete, IconArea, IconTextToSpeech,IconCopy } from '../assets/Icons';


import { Viewer, Worker, MinimalButton, PrimaryButton, RenderPage, RenderPageProps,LayerRenderStatus,
     Button, Position, Tooltip, ZoomEvent,  DocumentLoadEvent, PluginOnCanvasLayerRender , Plugin } from '@react-pdf-viewer/core';
import { RenderSearchProps, searchPlugin } from '@react-pdf-viewer/search';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { RenderZoomOutProps, RenderZoomInProps, RenderCurrentScaleProps, zoomPlugin } from '@react-pdf-viewer/zoom';
import { highlightPlugin, Trigger, MessageIcon, RenderHighlightTargetProps, HighlightArea, RenderHighlightContentProps } from '@react-pdf-viewer/highlight';
import { RenderSwitchSelectionModeProps, SelectionMode, selectionModePlugin } from '@react-pdf-viewer/selection-mode';

import { ConfirmDialog } from '../components/DialogComponents'; 
import { PageCanvas } from '../components/PageComponents';

import '@react-pdf-viewer/search/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/selection-mode/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';


//ANCHOR - 001 - FUNCTION INIT
const ReaderX = (props)=>{

    //ANCHOR - 002 - VARIABLES 
    var timeoutCountdown ; 
    var counter = 60000; 
    var timeout = 1800000;
    var senderTimer; 
    var stillReading = 1; 
    var _is_mounted = false;
    const windowWidth = Dimensions.get('window').width; 
    const windowHeight = Dimensions.get('window').height;
    const clientParams = useSelector(parameters); 
    

    //ANCHOR - 003 - REF INIT
    const timeoutCountId = useRef(null); 
    const senderTimerId = useRef(null);
    const stillReadingRef = useRef(); 
    const pageTotalRef = useRef(); 
    const noteRef = useRef(); 
    const pageIndexRef = useRef(); 
    const searchInputRef = useRef(); 
    const speechRef = useRef(); 
    const isDrawingRef = useRef(false); 
    

    //ANCHOR - 004 - STATE INIT
    const [progressState,setProgressState] = useState({ showProgressDialog : false, showProgress : true, showError : false, showSuccess : false, message : "" })
    const [bookInfoOpen, setBookInfoOpen ] = useState(false); 
    const [bookData, setBookData] = useState(props.route.params.book); 
    const [readData, setReadData] = useState(props.route.params.read); 
    const [contentURL, setContentURL] = useState("-"); 
    const [readMode, setReadMode ] = useState(false); 
    const [selectText, setSelectText]= useState(false); 
    const [notes, setNotes] = useState([]); 
    const [areaMode , setAreaMode] = useState(false); 
    const [areaHighlights, setAreaHighlights] = useState([]); 
    const [showCloseDialog, setShowCloseDialog] = useState(false);
    const [activeTool, setActiveTool] = useState("hand"); 
    const [copiedData, setCopiedData] = useState({ show : false}); 
    const [openDrawer, setOpenDrawer]= useState(false); 

    //ANCHOR - USE EFFECT - CONTENT URL
    useEffect(()=>{
        if(contentURL == "-"){
            if(readData.hasOwnProperty("id_baca")){
                setReadMode(true)
            }
            fetchContent(); 
        }
    },[contentURL])
    //ANCHOR - USE EFFECT - IS MOUNTED
    useEffect(()=>{
        _is_mounted = true; 
        return ()=>{
            _is_mounted = false;
        }
    },[])
    //ANCHOR - USE EFFECT - ADD KEYDOWN LISTENER
    useEffect(()=>{
        document.addEventListener('keydown', keyDownHandler);
        document.addEventListener("copy", copyHandler);
        return ()=>{
            document.removeEventListener('keydown', keyDownHandler);
            document.removeEventListener("copy", copyHandler); 
        }
    },[])
    //ANCHOR - F001 - PREVENT COPY KEY
    const keyDownHandler = (e)=>{
        if ((e.ctrlKey && e.key === 'c') || (e.metaKey && e.key === "c")) {
            e.preventDefault(); 
        }
    }
    const copyHandler = (e)=>{
        e.preventDefault(); 
    }

    //ANCHOR - F002 - FETCH CONTENT 
    const fetchContent = async()=>{
       
        let postData = {
            method : 'get', 
        }
        const response = await fetch(readData.url, postData);
        if(response.status == 200){
            const buffer = await response.arrayBuffer(); 
            const blob = new Blob([buffer], { type : 'application/pdf'});
            const blobURL = URL.createObjectURL(blob); 
             
            setContentURL(blobURL); 
            if(props.route.params.readMode){
                if(readData.file_anotasi != "0") {
                    //Kemungkinan ada anotasi 
                    //cek apakah formatnya sesuai dengan reader ini 
                    let annots = JSON.parse(readData.highlights); 
                    let correctAnnotationFormat = false;
                    try {
                        //console.log(JSON.stringify(annots)); 
                        if(annots.length > 0 ) {
                            correctAnnotationFormat = annots[0].hasOwnProperty("type"); 
                        }
                        if(correctAnnotationFormat){
                            setNotes(annots); 
                        }
                    } catch(error){
                        console.error("Error Reading Annotation"); 
                    }
                    setProgressState({...progressState, showProgress : false }); 
                } else {
                    setProgressState({...progressState, showProgress : false }); 
                }
            } else {
                setProgressState({...progressState, showProgress : false });
            }
        } else {
            setProgressState({...progressState, showProgress : false, showError : true, message : "Load Error!" });   
        }
    }

    //ANCHOR - F003 - RENDER PAGE CANVAS 
    const renderPage = (pageProps)=>(
        <PageCanvas
            renderPageProps={ pageProps }   
            bookData={ bookData }
            areaMode={ areaMode }
            highlights={ notes }
            highlightArea={(highlight)=>{
                let _notes = [...notes]; 
                _notes.push(highlight); 
                setNotes(_notes); 
            }}
        />
    )
    //ANCHOR - F004 - RENDER HIGHLIGHT TARGET 
    const renderHighlightTarget = (targetProps)=>{
        if(!areaMode){
                return(
                <div
                    style={{
                        background: '#eee',
                        display: 'flex',
                        position: 'absolute',
                        borderRadius : 8, 
                        left: `${targetProps.selectionRegion.left}%`,
                        top: `${targetProps.selectionRegion.top + targetProps.selectionRegion.height}%`,
                        transform: 'translate(0, 8px)',
                        zIndex : 1
                    }}
                >
                    <Tooltip
                        position={Position.TopCenter}
                        target={
                            <IconButton onClick={targetProps.toggle}>
                                <IconMessage width={24} height={24} />
                            </IconButton>
                        }
                        content={() => <Label style={{ width: '120px' }} text={"Tambah Catatan"} size={12} />}
                        offset={{ left: 0, top: -8 }}
                    />
                    <Tooltip
                        position={Position.TopCenter}
                        target={
                            <IconButton onClick={()=>{  
                                //console.log(targetProps.selectedText); 
                                let text = targetProps.selectedText; 
                                readText(text);
                                targetProps.cancel(); 
                            }}>
                                <IconTextToSpeech width={24} height={24} />
                            </IconButton>
                        }
                        content={() => <Label style={{ width: '100px' }} text={"Bacakan"} size={12} />}
                        offset={{ left: 0, top: -8 }}
                    />
                    <Tooltip
                        position={Position.TopCenter}
                        target={
                            <IconButton onClick={()=>{  
                                let text = targetProps.selectedText; 
                                setProgressState({...progressState, showProgressDialog : true, message : "Memeriksa izin salin"})
                                copyText(text); 
                                targetProps.cancel();  
                            }}>
                                <IconCopy width={24} height={24} />
                            </IconButton>
                        }
                        content={() => <Label style={{ width: '100px' }} text={"Salin"} size={12} />}
                        offset={{ left: 0, top: -8 }}
                    />
                </div>
            )
        } else {
            return null;
        }
    }
    //ANCHOR - F005 - RENDER HIGHLIGHT CONTENT 
    const renderHighlightContent = (contentProps) =>{
        const addNote = () => {
            let noteMessage = noteRef.current.value; 
            if(noteMessage != ""){
                const noteObj  = {
                    id : randomId(5), 
                    type : "note", 
                    content : noteMessage, 
                    highlightAreas : contentProps.highlightAreas, 
                    quote : contentProps.selectedText
                }
                setNotes([...notes, noteObj]); 
                contentProps.cancel(); 
            }
        };

        return(
            <div
                style={{
                    background: '#fff',
                    border: '1px solid rgba(0, 0, 0, .3)',
                    borderRadius: '2px',
                    padding: '8px',
                    position: 'absolute',
                    left: `${contentProps.selectionRegion.left}%`,
                    top: `${contentProps.selectionRegion.top + contentProps.selectionRegion.height}%`,
                    zIndex: 2,
                }}
            >
                <div>
                    <textarea
                        ref={ noteRef }
                        rows={3}
                        style={{
                            border: '1px solid rgba(0, 0, 0, .3)',
                        }}
                    ></textarea>
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginTop: '8px',
                    }}
                >
                    <div style={{ marginRight: '8px' }}>
                        <PrimaryButton onClick={addNote}>Add</PrimaryButton>
                    </div>
                    <Button onClick={contentProps.cancel}>Cancel</Button>
                </div>
            </div>
        )
    }
    //ANCHOR - F006 - RENDER HIGHLIGHTS
    const renderHighlights = (highlightProps) => (
        <div>
            {notes.map((item, index) => {
                if(item.type == "note"){
                    return(
                        <React.Fragment key={item.id}>
                            {item.highlightAreas
                                .filter((area) => area.pageIndex === highlightProps.pageIndex)
                                .map((area, idx) => (
                                    <div
                                        key={idx}
                                        style={Object.assign(
                                            {},
                                            {
                                                background: '#fff200',
                                                opacity: 0.4,
                                                pointer : "cursor"
                                            },
                                            highlightProps.getCssProperties(area, highlightProps.rotation)
                                        )}  
                                    />
                                ))}
                        </React.Fragment>
                    )
                } else {
                    return null; 
                }
            })}
        </div>
    );
    //ANCHOR - F007 - SET PLUGINS 
    const searchPluginInstance = searchPlugin();
    const { Search, highlight } = searchPluginInstance;  
    const pageNavigationPluginInstance = pageNavigationPlugin();
    const { GoToFirstPage, GoToLastPage, GoToNextPage, GoToPreviousPage, 
    CurrentPageLabel, NumberOfPages, jumpToPage } = pageNavigationPluginInstance;
    const zoomPluginInstance = zoomPlugin();
    const { CurrentScale, ZoomIn, ZoomOut } = zoomPluginInstance;
    const highlightPluginInstance = highlightPlugin({
        renderHighlightTarget, 
        renderHighlightContent, 
        renderHighlights,
    }); 
    const { jumpToHighlightArea, switchTrigger } = highlightPluginInstance;    
    const selectionModePluginInstance = selectionModePlugin({
        selectionMode : SelectionMode.Hand
    });
    const { SwitchSelectionMode } = selectionModePluginInstance;
    
    //ANCHOR - F008 - DELETE NOTE
    const deleteNote = (note)=>{
        let _notes =[...notes]; 
        let i = 0 ; 
        _notes.map((_note)=>{
            if(_note.id == note.id){
                _notes.splice(i, 1); 
            }
            i++; 
        });
        setNotes(_notes); 
    }
    //ANCHOR - F009 - NOTE LIST
    const noteList = ()=>{
        return(
            <Box
                sx={{ width : 300, height : "auto", backgroundColor : "#dfe6e9" }}
                role="presentation"
            >
                <View style={{margin : 8 }}>
                    <Label
                        size={16}
                        text={"Daftar Catatan"}
                        weight={"600"}
                        style={{marginLeft : 8 }}
                    />
                    <View style={{ backgroundColor : "silver" , marginLeft : 8 , marginRight : 8 , marginTop : 8 }}/>
                </View>
                {
                    notes.length == 0 && 
                    <View style={{width : "100%", height : "auto", alignItems : "center", justifyContent : "center"}}>
                        <Label 
                            size={14}
                            weight={"600"}
                            text={"Belum ada catatan"} 
                            color={"#95a5a6"}
                        />
                    </View>
                }
                {
                    notes.length > 0 &&
                    <>
                    {
                        notes.map((item, index)=>{
                            return(
                                <div key={item.id} style={{margin : 8, backgroundColor : "white", borderRadius : 8 }}>
                                    <View style={{flexDirection : "row", marginLeft : 8}} >
                                        <View style={{
                                            backgroundColor : "silver",
                                            width : 2, 
                                            marginTop : 8, 
                                            height : "auto",
                                            marginRight : 8,
                                        }}/>
                                        {
                                            item.type == "note" && 
                                            <Label 
                                                size={12}
                                                text={ "\""+item.quote+"\"" } 
                                                textAlign={"justify"}
                                                style={{marginTop : 8, marginRight : 8 }}
                                            />
                                        }
                                        {
                                            item.type == "area" && 
                                            <img 
                                                src={ item.quote } 
                                                style={{
                                                    marginTop : 8, 
                                                    marginRight : 8 , 
                                                    overflow : "scroll"
                                                }}
                                            />
                                        }
                                    </View>
                                    <Label 
                                        size={12}
                                        text={ item.content }
                                        style={{marginTop : 8, marginLeft : 8 , cursor : "pointer" }}
                                        weight={"600"}
                                        onClick={()=>{
                                            if(item.type == "note"){
                                                jumpToHighlightArea(item.highlightAreas[0]); 
                                            } else {
                                                jumpToPage(item.pageIndex); 
                                            }
                                        }}
                                    />
                                    <View style={{flexDirection : "row-reverse", marginRight : 8   }}>
                                        <IconButton
                                            onClick={()=>{
                                                deleteNote(item); 
                                            }}
                                        >
                                            <IconDelete width={24} height={24} />
                                        </IconButton>
                                    </View>
                                </div>
                            )
                        }) 
                    }
                    </>
                }
            </Box>
        )
    }
    //ANCHOR - F010 - READ ALOUD 
    const readText = (text)=>{
        let speech = new SpeechSynthesisUtterance(); 
        speech.lang = "id"; 
        speech.pitch = 1;
        speech.rate = 0.9
        speech.text = text;
        speechRef.current = window.speechSynthesis;
        speechRef.current.speak(speech); 
    }
    //ANCHOR - F011 - COPY TEXT FUNCTION CHECK
    const copyText = async(text)=>{
        let formData = new FormData(); 
        formData.append("id_konten", bookData.id); 
        formData.append("type", "check"); 
        formData.append("total", pageTotalRef.current); 
        const response = await executePost(api.copyTextInfo, formData); 
        if(response.code == resCode.ok){            
            setProgressState({...progressState, showProgressDialog : false})
            try {
                let page = 0; 
                if(pageIndexRef.current > parseInt(response.info_buku.hal_romawi)){
                    page = (pageIndexRef.current - parseInt(response.info_buku.hal_romawi))
                } else {
                    page = romanize(pageIndexRef.current + 1); 
                    
                }
                let _copiedData = {
                    show : true, 
                    sisa : response.sisa, 
                    text : text,
                    page : page,
                    data : response.info_buku
                }
                //console.log("HALAMAN ROMAWI: "+ response.info_buku.hal_romawi); 
                //console.log("INDEX: " + pageIndexRef.current); 
                setCopiedData(_copiedData); 
            } catch(error){
                console.error(error.message);
            }
        } else {
            setProgressState({...progressState, showProgressDialog : false})
        }
    }
    //ANCHOR - F012 - COPY TEXT OPT
    const handleCopyTextOpt = ()=>{
        setCopiedData({...copiedData, show : false })
        setProgressState({...progressState, showProgressDialog : true, message : "Menyalin teks"}); 
        inputCopyText(); 
    }

    //ANCHOR - F013 - INPUT COPY TEXT 
    const inputCopyText = async()=>{
        let formData = new FormData(); 
        formData.append("id_konten",bookData.id); 
        formData.append("total", pageTotalRef.current); 
        formData.append("type", "input"); 
        formData.append("page", pageIndexRef.current); 
        const response = await executePost(api.copyTextInfo, formData); 
        if(response.code == resCode.ok){
            setProgressState({...progressState, showProgressDialog : false, showSuccess : true, message : "Berhasil Disalin"}); 
            window.electron.sendMessage("WRITE_CLIPBOARD", copiedData); 
        } else {
            setProgressState({...progressState, showError : false, message : response.msg}); 
        }
    }

    //ANCHOR - F014 - HANDLE DOCUMENT LOAD
    const handleDocumentLoad = (e)=>{
        pageTotalRef.current = e.doc.numPages; 
        stillReading = "1"; 
        if(props.route.params.readMode){
            timeoutCountId.current = timeoutCountdown = setInterval(()=>{
                timeout -= 1000; 
                
            },1000)

            senderTimerId.current = senderTimer = setInterval(()=>{
                counter -= 1000; 
                if(counter == 0) {
                    if(timeout == 0 ){
                        stillReading = "0"; 
                    }
                    //ANCHOR - SUB001 - SEND PULSE 
                    let formData = new FormData(); 
                    formData.append("id_baca", readData.id_baca); 
                    formData.append("status", stillReading); 
                    sendReadStatus(formData); 
                }
            }, 1000)
            //ANCHOR - SUB002 - ON SEARCH KALIMAT
            if(props.route.params.hasOwnProperty("search")){
                let _keyword = props.route.params.search.keyword; 
                searchInputRef.current.value = _keyword; 
                setTimeout(()=>{
                    highlight([_keyword]); 
                }, 1000)
            }
        }   
    }

    //ANCHOR - F015 - SEND READ STATUS 
    const sendReadStatus = async(formData)=>{
        const response = await executePost(api.updateReadStatus, formData); 
        if(response.code == resCode.ok){
            //console.log("READ STATUS OK"); 
            counter = 60000; 
        } else {
            if(response.code == resCode.error){
                //TODO - Force Logout 

            }
        }
    }

    //ANCHOR - F016 - Handle Zoom 
    const handleZoom = (e)=>{
        //console.log(`Zoom to ${e.scale}`);
    }

    //ANCHOR - F017 - CLOSE BOOK 
    const handleCloseBook = ()=>{
        setShowCloseDialog(false); 
        if(speechRef.current != null){
            speechRef.current.cancel(); 
        }
        if(props.route.params.readMode){
            //MODE BACA
            setProgressState({...progressState, showProgressDialog : true, message : "Mengambalikan stok buku"});
            let formData = new FormData(); 
            if(notes.length > 0 ) {
                formData.append("id_konten", bookData.id); 
                formData.append("highlights", JSON.stringify(notes)); 
                saveAnnotation(formData); 
            } else {
                formData.append("id_baca", readData.id_baca); 
                formData.append("id_konten", bookData.id); 
                formData.append("page_index", pageIndexRef.current ); 
                executeCloseBook(formData); 
            }
        } else {
            props.navigation.goBack(); 
        }
    }
    //ANCHOR - F018 - EXECUTE CLOSE BOOK
    const executeCloseBook = async(formData)=>{
        clearInterval(timeoutCountId.current); 
        clearInterval(senderTimerId.current); 
        const response = await executePost(api.closeBook , formData); 
        if(response.code == resCode.ok){
            setProgressState({...progressState, showProgressDialog : false });
            props.navigation.goBack(); 
        } else {
            setProgressState({...progressState, showProgressDialog : false});
            props.navigation.goBack(); 
        }
    }

    //ANCHOR - F019 - SAVE ANNOTATION
    const saveAnnotation = async(formData)=>{
        const response = await executePost(api.saveAnnotation, formData); 
        let _formData = new FormData(); 
        _formData.append("id_baca", readData.id_baca);
        _formData.append("id_konten", bookData.id);
        _formData.append("page_index", pageIndexRef.current); 
        executeCloseBook(_formData); 
    }

    //ANCHOR - F020 - HANDLE LOAD ERROR
    const handleLoadError = (error)=>{
        let message = '';
        switch (error.name) {
            case 'InvalidPDFException':
                message = 'The document is invalid or corrupted';
                break;
            case 'MissingPDFException':
                message = 'The document is missing';
                break;
            case 'UnexpectedResponseException':
                message = 'Unexpected server response';
                break;
            default:
                message = 'Cannot load the document';
                break;
        }
        setProgressState({...progressState, showError : true , message : message}); 
    }

    //ANCHOR - 005 - RETURN VIEW
    return(
        <>
        {
            progressState.showProgress &&
            <View style={{ flex : 1, height : windowHeight, alignItems : "center", justifyContent : 'center'}}>
                <Progress 
                    text={ progressState.message }
                />
            </View>
        }
        {
            progressState.showError && 
            <ErrorPage 
                text={progressState.message }
                onClick={()=>{
                    if(props.route.params.readMode){
                        let formData = new FormData(); 
                        formData.append("id_baca", readData.id_baca); 
                        formData.append("id_konten", bookData.id);  
                        formData.append("page_index", readData.page_index);  
                        setProgressState((progressState)=>({...progressState, showError : false }))
                        executeCloseBook(formData); 
                        
                    } else {
                        setProgressState()
                        props.navigation.goBack(); 
                    }
                }}
            />
        }
        {
            (!progressState.showProgress && !progressState.showError) && 
            <View style={{flex : 1, width : windowWidth, height : windowHeight }}>
                {
                //ANCHOR - 006 - TOOLBAR
                }
                <View style={{
                    backgroundColor : clientParams.warna_header, 
                    height : 48, 
                    flexDirection : "row",
                    alignItems : "center",
                    paddingLeft : 12, 
                    paddingRight : 12
                }}>

                    <IconButton
                        onClick={()=>{
                            setShowCloseDialog(true);
                        }}
                    >
                        <CloseIcon 
                            sx={{ color : "white"}}
                        />
                    </IconButton>
                    <Label 
                        size={14}
                        text={ bookData.judul }
                        weight={"600"}
                        align={"left"}
                        color={"white"}
                        style={{
                            minWidth : 120, 
                            paddingLeft : 8, 
                            paddingRight : 8, 
                            display : "-webkit-box", 
                            WebkitBoxOrient: "vertical", 
                            WebkitLineClamp : 1, 
                            overflow : "hidden", 
                            textOverflow : "ellipsis",
                            flex : 1
                        }}
                    />
                    {
                        readData.copyDaftarPustaka == 1 && 
                        <IconButton
                            onClick={()=>{ setBookInfoOpen(true)}}
                        >
                            <InfoOutlinedIcon 
                                sx={{ color : "white"}}
                            />
                        </IconButton>
                    }
                </View>
                {
                    //ANCHOR - 007 - VIEWER AREA
                }
                <div style={{height : (windowHeight - 48) , width : windowWidth, backgroundColor : "#ecf0f1"}}>
                    {
                        //ANCHOR - 008 - BOTTOM BAR
                    }
                    <Paper 
                        elevation={3}
                        style={{ 
                        backgroundColor : clientParams.warna_header, 
                        width : (windowWidth - 128),
                        borderRadius : 12, 
                        height : 58,
                        position : "absolute", 
                        bottom : 16, 
                        zIndex : 1,
                        left: '50%',
                        alignItems : "center", 
                        transform: 'translate(-50%, 0%)',
                    }}>
                        <View style={{flexDirection : "row", height : 58, alignItems : "center", paddingLeft : 12, paddingRight : 12 }}>
                            {
                                //ANCHOR - 009 - SEARCH TOOL
                            }
                            <Search>
                                {
                                    ( renderSearchProps )=>{
                                        return(
                                            <View style={{flexDirection : "row",  alignItems : "center", backgroundColor : "white", borderRadius : 8, marginRight : 12}}>
                                                <TextInput 
                                                    ref={ searchInputRef }
                                                    style={{
                                                        borderRadius : 8,
                                                        outline : "none",
                                                        paddingLeft : 12, 
                                                        paddingRight : 12, 
                                                        paddingTop : 8, 
                                                        paddingBottom : 8,
                                                        fontSize : 14, 
                                                        flex : 1, 
                                                        fontFamily : "Poppins"
                                                    }}
                                                    placeholder={"Pencarian"}
                                                    placeholderTextColor={"silver"}
                                                    onChangeText={(text)=>{
                                                        renderSearchProps.setKeyword(text)
                                                    }}
                                                    onKeyPress={(e)=>{
                                                        if(e.nativeEvent.key == "Enter"){
                                                            renderSearchProps.search(); 
                                                        }
                                                    }}
                                                />
                                                <IconButton
                                                    onClick={renderSearchProps.jumpToPreviousMatch}
                                                >
                                                    <KeyboardArrowUpOutlinedIcon />
                                                </IconButton>
                                                <IconButton
                                                    onClick={renderSearchProps.jumpToNextMatch}
                                                >
                                                    <KeyboardArrowDownOutlinedIcon />
                                                </IconButton>
                                            </View>
                                        )
                                    
                                    }
                                }
                            </Search>
                            <View style={{ width : 0.3, backgroundColor : "white", height : 32, marginLeft : 12 , marginRight : 12 }} />
                            {
                                //ANCHOR - 010 - NAVIGATION TOOL
                            }
                            <View style={{ flexDirection : "row", alignItems : "center", borderRadius : 8}}>
                                <GoToFirstPage>
                                    {
                                        (pageProps)=>{
                                            return(
                                                <IconButton
                                                    onClick={pageProps.onClick}
                                                >
                                                    <FirstPageOutlinedIcon sx={{ color : "white" }} />
                                                </IconButton>
                                            )
                                        }
                                    }
                                </GoToFirstPage>
                                <GoToPreviousPage>
                                    {
                                        (pageProps)=>{
                                            return(
                                                <IconButton
                                                    onClick={pageProps.onClick}
                                                >
                                                    <KeyboardArrowLeftOutlinedIcon sx={{ color : "white" }} />
                                                </IconButton>
                                            )
                                        }
                                    }
                                </GoToPreviousPage>
                                <CurrentPageLabel>
                                    {
                                        (pageProps)=>{
                                            return(
                                                <Label 
                                                    size={14}
                                                    weight={"600"}
                                                    text={pageProps.pageLabel + "/"}
                                                    color={"white"}
                                                    style={{marginLeft : 8 }}
                                                />
                                            )
                                        }
                                    }
                                </CurrentPageLabel>
                                <NumberOfPages>
                                    {
                                        (pageProps)=>{
                                            return(
                                                <Label 
                                                    size={14}
                                                    weight={"600"}
                                                    text={pageProps.numberOfPages}
                                                    color={"white"}
                                                    style={{marginRight : 8 }}
                                                />
                                            )
                                        }
                                    }
                                </NumberOfPages>
                                <GoToNextPage>
                                    {
                                        (pageProps)=>{
                                            return(
                                                <IconButton
                                                    onClick={pageProps.onClick}
                                                >
                                                    <KeyboardArrowRightOutlinedIcon sx={{ color : "white" }} />
                                                </IconButton>
                                            )
                                        }
                                    }
                                </GoToNextPage>
                                <GoToLastPage>
                                    {
                                        (pageProps)=>{
                                            return(
                                                <IconButton
                                                    onClick={ pageProps.onClick }
                                                >
                                                    <LastPageOutlinedIcon sx={{ color : "white" }}  />
                                                </IconButton>

                                            )
                                        }
                                    }
                                </GoToLastPage>
                            </View>
                            <View style={{ width : 0.3, backgroundColor : "white", height : 32, marginLeft : 12 , marginRight : 12 }} />
                            {
                                //ANCHOR - 011 - ZOOM TOOL
                            }
                            <View style={{ flexDirection : "row",  alignItems : "center", borderRadius : 8}}>
                                <ZoomOut>
                                    {
                                        (zoomProps)=>{
                                            return(
                                                <IconButton
                                                    onClick={ zoomProps.onClick }
                                                >
                                                    <ZoomOutOutlinedIcon sx={{ color : "white" }} />
                                                </IconButton>
                                            )
                                        }
                                    }
                                </ZoomOut>
                                <CurrentScale>
                                    {
                                        (scaleProps )=>{
                                            return(
                                                <Label 
                                                    size={14}
                                                    weight={"600"}
                                                    text={`${Math.round(scaleProps.scale * 100)}%`}
                                                    color={"white"}
                                                    style={{marginLeft : 8 , marginRight : 8 }}
                                                />
                                            )
                                        }
                                    }
                                </CurrentScale>
                                <ZoomIn>
                                    {
                                        (zoomProps)=>{
                                            return(
                                                <IconButton
                                                    onClick={zoomProps.onClick}
                                                >
                                                    <ZoomInOutlinedIcon sx={{ color : "white" }} />
                                                </IconButton>
                                            )
                                        }
                                    }
                                </ZoomIn>
                            </View>
                            <View style={{ width : 0.3, backgroundColor : "white", height : 32, marginLeft : 12 , marginRight : 12 }} />
                            {
                                //ANCHOR - 012 - SELECT TOOL 
                            }
                            <SwitchSelectionMode mode={SelectionMode.Hand}>
                                {
                                    //ANCHOR - 013 - HAND TOOL
                                    (selectProps)=>(
                                        <View>
                                            <IconButton
                                                onClick={ ()=>{
                                                    setSelectText(false);
                                                    switchTrigger(Trigger.NONE)
                                                    setAreaMode(false);
                                                    selectProps.onClick();
                                                    setActiveTool("hand");

                                                }}
                                            >
                                                <IconHand
                                                    width={24}
                                                    height={24}
                                                    color={"white"}
                                                />
                                            </IconButton>
                                            {
                                                activeTool == "hand" && 
                                                <View style={{ height : 2.5 , backgroundColor : "white"}} />
                                            }
                                        </View>
                                    )
                                }
                            </SwitchSelectionMode>
                            <SwitchSelectionMode mode={SelectionMode.Text}>
                                {
                                    //ANCHOR - 014 - HIGHLIGHT TOOL
                                    (selectProps)=>(
                                        <View style={{marginLeft : 8 }}>
                                            <IconButton
                                                onClick={()=>{
                                                    setSelectText(true);
                                                    selectProps.onClick(); 
                                                    switchTrigger(Trigger.TextSelection);
                                                    setAreaMode(false);
                                                    setActiveTool("select");
                                                }}
                                            >
                                                <IconText
                                                    width={24}
                                                    height={24}
                                                    color={"white"}
                                                />
                                            </IconButton>
                                            {
                                                activeTool == "select" && 
                                                <View style={{ height : 2.5 , backgroundColor : "white"}} />
                                            }
                                        </View>
                                    )
                                }
                            </SwitchSelectionMode>
                            <SwitchSelectionMode mode={SelectionMode.Text}>
                                {
                                    //ANCHOR - 015 - AREA TOOL
                                    (selectProps)=>(
                                        <View style={{marginLeft : 8 }}>
                                            <IconButton
                                                onClick={()=>{
                                                    switchTrigger(Trigger.NONE); 
                                                    setSelectText(true);
                                                    setAreaMode(true);
                                                    setActiveTool("area");
                                                    selectProps.onClick(); 
                                                }}
                                            >
                                                <IconArea
                                                    width={24}
                                                    height={24}
                                                    color={"white"}
                                                />
                                            </IconButton>
                                            {
                                                activeTool == 'area' && 
                                                <View style={{ height : 2.5 , backgroundColor : "white"}} />
                                            }
                                        </View>
                                    )
                                }
                            </SwitchSelectionMode>
                            <View style={{ width : 0.3, backgroundColor : "white", height : 32, marginLeft : 12 , marginRight : 12 }} />
                            {
                                //ANCHOR - 016 - DRAWER BUTTON
                            }
                            <View style={{flexDirection : "row", alignItems : "center"}}>
                                <IconButton
                                    onClick={()=>{
                                        setOpenDrawer(true)
                                    }}
                                >
                                    <IconMenu color={"white"} width={24} height={24}/>
                                </IconButton>
                                <Label 
                                    size={14}
                                    color={"white"}
                                    style={{marginLeft : 12 }}
                                    text={"Catatan"}
                                />
                            </View>
                        </View>
                    </Paper>
                    {
                        //ANCHOR - 017 - VIEWER TAG
                    }
                    <div style={{position : "absolute", top : 48, left : 0, bottom : 0, right : 0, zIndex : 0 }}>
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                            <Viewer
                                fileUrl={ contentURL }
                                plugins={[searchPluginInstance, pageNavigationPluginInstance, 
                                    zoomPluginInstance, highlightPluginInstance, 
                                    selectionModePluginInstance]}
                                renderPage={renderPage}
                                initialPage={ props.route.params.hasOwnProperty("search") ? props.route.params.search.hal_idx : readData.page_index }
                                onDocumentLoad={ handleDocumentLoad } 
                                onZoom={ handleZoom }
                                onPageChange={(e)=>{
                                    pageIndexRef.current = e.currentPage;
                                    stillReading = "1"; 
                                    timeout = 1800000; 
                                }}
                                renderError={ handleLoadError }

                            />
                        </Worker>
                    </div>
                    {
                        //ANCHOR - 018 - DRAWER CONTENT
                    }
                    <Drawer
                        anchor={"left"}
                        open={openDrawer}
                        onClose={()=>{
                            setOpenDrawer(false)
                        }}
                        PaperProps={{
                            style : {
                                backgroundColor : "#dfe6e9"
                            }
                        }}
                    >
                        {
                            noteList()
                        }
                    </Drawer>
                </div>
            </View>
        }
            {
                //ANCHOR - 019 - BOOK INFO DIALOG
            }
            <BookInfoDialog 
                open={ bookInfoOpen}
                onClose={()=>{
                    setBookInfoOpen(false)
                }}
                data={ props.route.params.book }
                width={ windowWidth - (windowWidth * 0.4)}
                height={ windowHeight - (windowHeight * 0.1)}
            />
            {
                //ANCHOR - 020 - CLOSE CONFIRM DIALOG
            }
            <ConfirmDialog 
                open={ showCloseDialog }
                message={"Selesai membaca buku ini?"} 
                labelPositive={"OK"}
                labelNegative={"Batal"}
                onPositiveClick={ handleCloseBook }
                onNegativeClick={()=>{
                    setShowCloseDialog(false)
                }}
            />
            {
                //ANCHOR - 021 - PROGRESS DIALOG
            }
            <ProgressDialog 
                open={ progressState.showProgressDialog } 
                message={ progressState.message }
            />
            {
                //ANCHOR - 022 - OPT TEXT DIALOG
            }
            <CopyTextOptDialog
                open={copiedData.show}
                width={450}
                height={300}
                copyRemaining={copiedData.hasOwnProperty("sisa") ? copiedData.sisa : 0} 
                onCloseClick={()=>{
                    let _copiedData = {...copiedData}
                    _copiedData.show = false;
                    setCopiedData(_copiedData); 
                }}  
                onNegativeClick={()=>{
                    let _copiedData = {...copiedData}
                    _copiedData.show = false;
                    setCopiedData(_copiedData); 
                }}
                onPositiveClick={ handleCopyTextOpt }
            />
            {
                //ANCHOR - 023 - SUCCESS DIALOG 
            }
            <SuccessDialog 
                open={ progressState.showSuccess} 
                message={progressState.message} 
                label={"OK"} 
                onClick={()=>{
                    let _progressState = { ...progressState}
                    _progressState.showSuccess = false; 
                    setProgressState(_progressState)
                }}
            />

        </>
    )


}


export { ReaderX } ; 