import React, { useState, useEffect } from 'react'; 
import { View, StyleSheet, FlatList,Pressable, Dimensions } from 'react-native'; 
import { Progress, ProgressDialog, ErrorDialog, SuccessDialog,NewsDetailDialog, ConfirmDialog } from '../components/DialogComponents';
import { executeGet, executePost, api, resCode } from '../utilities/Network';
import { useSelector, useDispatch } from 'react-redux'
import { setClientConfiguration, parameters, userParams } from '../reducers/parameterSlice';
import { Label, PushButton } from '../components/FormComponents';
import { useIsMounted } from '../utilities/System';
import { Paper, Card, CardActionArea, CardHeader,CardMedia, CardContent } from '@mui/material';



const BookRack = (props)=>{
    const clientParams = useSelector(parameters); 
    const isMounted = useIsMounted();
    let initProgressState = {
        showProgressDialog : false, 
        showProgress : false,
        showError : false, 
        showConfirm : false, 
        message : "Memuat Data"
    }
    const [progressState,setProgressState] = useState(initProgressState);
    const [initData, setInitData ]= useState(props.data);
    const [rackData, setRackData] = useState([]); 
    const [borrowId, setBorrowId] = useState()
    const [bookData, setBookData] = useState({
        id : "-"
    });
    
    useEffect(()=>{
        console.log("USE EFFECT INIT RACK");
        if(!initData.loaded){
            let progress = { ...progressState};
            progress.showProgress = true;
            progress.message = "Memuat Data";
            if(isMounted()){
                setProgressState(progress);
                loadData();
            }
        }

        // if(initData.loaded){
        //     renderData();
        // }

    },[initData]);

    useEffect(()=>{
        if(bookData.id != "-"){
            readCheck(bookData.id);
        } 
    },[bookData])

    
    const loadData = async()=>{
        let formData = new FormData(); 
        formData.append("page", "1"); 
        formData.append("limit", "100"); 

        const response = await executePost(api.bookRack, formData); 
        let progress = { ...progressState};
        if(isMounted()){
            if(response.code == resCode.ok) {
                progress.showProgress = false; 
                setProgressState(progress); 
                if(response.show > 0 ) {
                    let mod = parseInt(response.show) % 2; 
                    let data = response.data; 
                    if(mod > 0){
                        let add_factor = 2 - mod; 
                        for(let i=0;i<add_factor;i++){
                            data.push({ dummy : i})
                        }
                    }
                    setRackData(data); 
                }

            } else {
                if(response.code != resCode.error){
                    progress.showError = true; 
                    progress.message = response.msg; 
                    setProgressState(progress)
                } else {
                    //ANCHOR - Force Logout
                    window.postMessage({command : "FORCE_LOGOUT"}, "*");

                }   
            }
        }
    }

    //ANCHOR - On Book Return Click 
    const onBookReturnClick = (item)=>{
        if(isMounted()){
            let id_pinjam = item.id_pinjam; 
            let _progressState = { ...progressState} 
            _progressState.showConfirm = true; 
            _progressState.message = "Kembalikan buku ini?";
            setProgressState(_progressState);
            setBorrowId(id_pinjam); 
        }
    }

    //ANCHOR - On Book Read 
    const onBookReadClick = (item)=>{
        console.log(JSON.stringify(item));
        let _progressState = {...progressState}
        _progressState.showProgressDialog = true; 
        _progressState.message = "Checking"; 
        setProgressState(_progressState); 
        fetchDetails(item.id); 
    }

    //ANCHOR - Book Returning 
    const bookReturningRequest = async()=>{
        let formData = new FormData(); 
        formData.append("id_pinjam", borrowId); 
        const response = await executePost(api.bookReturn, formData); 

        let _progressState = { ...progressState } 
        if(response.code == resCode.ok){
            _progressState.showProgress = false; 
            _progressState.showConfirm = false; 
            setProgressState(_progressState);
            setRackData([]); 
            setInitData({ loaded : false });          
        } else {
            _progressState.showProgress = false;
            if(response.code != resCode.error){
                _progressState.showError = true; 
                _progressState.message = response.msg; 
                setProgressState(_progressState); 
            } else {
                //ANCHOR - Force Logout
                window.postMessage({command : "FORCE_LOGOUT"}, "*");
            }
        }
    }


    //ANCHOR -  RENDER ITEM
    const renderItem = ({ item, index, separators})=>{

        if(!item.hasOwnProperty("dummy")){
            return(
                <View 
                    style={{
                    cursor : "pointer", 
                    flex : 1, 
                    height : 170, 
                    flexDirection : "row", 
                    marginBottom : 12 }}
                    
                >
                    <img 
                        src={ item.cover }
                        style={{
                            width : 120, 
                            borderRadius : 12
                        }}
                    />

                    <View style={{flex : 1, marginLeft : 16 }}>
                        <Label 
                            size={14}
                            weight={"600"}
                            style={{marginRight : 32 }}
                            text={item.judul_buku }
                        />
                        <Label
                            size={12}
                            weight={"600"}
                            color={"#2980b9"}
                            text={item.nama_penulis}
                            style={{marginTop : 8 }}
                        />
                        <Label
                            size={12}
                            weight={"600"}
                            text={item.nama_penerbit}
                            style={{marginTop : 4 }}
                        />

                        <View 
                            style={{
                                position : "absolute", 
                                bottom : 4, 
                                right : 16, 
                                flexDirection : "row-reverse"
                            }}
                        >

                            <PushButton
                                variant={"contained"}
                                text={"Baca"}
                                onClick={()=>{ onBookReadClick(item)}}
                            />
                            <PushButton
                                variant={"outlined"}
                                text={"Kembalikan"}
                                style={{marginRight : 16 }}
                                onClick={()=>{ onBookReturnClick(item)} }
                            />
                        </View>
                    </View>
                </View>
            )
        } else {
            return(
                <View style={{flex : 1}}>
                </View>
            )
        }
    }   

    //ANCHOR - GET DETAIL CONTENT
    const fetchDetails = async(id)=>{
        let formData = new FormData();
        formData.append("id_konten", id); 

        const response = await executePost(api.detailContent, formData); 
        let progress = { ...progressState}
        if(response.code == resCode.ok){
            console.log(JSON.stringify(response.data)); 
            setBookData(response.data); 
            //readCheck(id); 


        } else {
            if( response.code != resCode.error){
                //TODO - Error Page
            } else {
                //ANCHOR - Force Logout
                window.postMessage({command : "FORCE_LOGOUT"}, "*");
            }
        }

    }

    //ANCHOR - CHECK PINJAM 
    const readCheck = async(id)=>{
        let formData = new FormData(); 
        formData.append("id_konten", id);
        const response = await executePost(api.checkPinjam ,formData); 
        let _progressState = {...progressState} 
        if(response.code == resCode.ok){
            _progressState.showProgressDialog = false; 
            setProgressState(_progressState); 
            let data = {
                book : bookData, 
                read : response, 
                readMode : false
            }
            //console.log(JSON.stringify(data));
            props.navigation.navigate("Reader", data); 
        } else {
            if(response.code != resCode.error){
                _progressState.showError = true; 
                _progressState.message = response.msg;
                setProgressState(_progressState); 
            } else {
                //ANCHOR - FORCE LOGOUT 
                window.postMessage({command : "FORCE_LOGOUT"}, "*");
            }
        }


    }

    //ANCHOR - Book Rack Data 
    return(
        <View style={{flex : 1}}>
            {
                progressState.showProgress && 
                <Progress 
                    text={ progressState.message }
                />
            }
            {
                (!progressState.showProgress && rackData.length > 0) &&
                <FlatList
                    keyExtractor={(item, index)=>"news_index_"+index} 
                    data={rackData}
                    renderItem={renderItem}
                    numColumns={2}
                    showsVerticalScrollIndicator={false}
                    contentContainerStyle={{marginLeft : 16, marginRight : 16 , marginTop : 16}}
                />
            }
            <ConfirmDialog
                open={ progressState.showConfirm }
                message={ progressState.message }
                labelPositive={"OK"}
                labelNegative={"Batalkan"}
                onPositiveClick={()=>{
                    if(isMounted()){
                        let _progressState = { ...progressState} 
                        _progressState.showConfirm = false;
                        _progressState.showProgress = true; 
                        _progressState.message = "Mengambalikan Buku";
                        setProgressState(_progressState); 
                        bookReturningRequest();
                        
                    }
                }}
                onNegativeClick={()=>{
                    if(isMounted()){
                        let _progressState = { ...progressState} 
                        _progressState.showConfirm = false;
                        setProgressState(_progressState); 
                    }
                }}
            />
            <ErrorDialog 
                open={progressState.showError }
                message={ progressState.message }
                label={"OK"}
                onClick={()=>{
                    if(isMounted()){
                        let _progressState = { ...progressState }
                        _progressState.showError = false; 
                        setProgressState(_progressState);
                    }
                }}
            />
            <ProgressDialog 
                open={progressState.showProgressDialog}
                message={progressState.message}
            />
        </View>
    )

}


export { BookRack }