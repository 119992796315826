import React, { useState, useEffect, useMemo } from 'react'; 
import { View, StyleSheet, FlatList } from 'react-native'; 
import { Progress, ProgressDialog, ErrorDialog, SuccessDialog } from '../components/DialogComponents';
import { executeGet, executePost, api, resCode } from '../utilities/Network';
import { useSelector, useDispatch } from 'react-redux'
import { setClientConfiguration, parameters, userParams } from '../reducers/parameterSlice';
import { Banners, Contents, TopReader, Recommended, CategoryMenu, IntegratedMenu } from "./HomeSections";
import { useIsMounted } from '../utilities/System';
import { Categories } from './Categories';
import { SectionContent } from './SectionContent';
import { AppIntegration } from './AppIntegration';


const Home = (props)=>{
    const clientParams = useSelector(parameters); 
    //const dispatch = useDispatch(); 
    const isMounted = useIsMounted()
    var _is_mounted = false;
    let initProgressState = {
        showProgress : false,
        showError : false, 
        message : ""
    }
    let initPageState = {
        showHome : true, 
        showCategory  : false, 
        categoryId : "", 
        categoryTitle : "", 
        showSectionContent : false,
        sectionId : "",
        sectionTitle : "",
        showIntegratedApp : false

    }

    const [progressState,setProgressState] = useState(initProgressState)
    const [initData, setInitData ]= useState(props.data);
    const [homepageData, setHomepagedata] = useState([]);
    const [pageState, setPageState ] = useState(initPageState)
    
    useEffect(()=>{
        _is_mounted = true;
        if(!initData.loaded){
          
            let progress = { ...progressState};
            progress.showProgress = true;
            progress.message = "Memuat Data";
            if(isMounted()){
                setProgressState(progress);
                loadInitData();
            }
        }

        if(initData.loaded && isMounted()){
            //console.log(JSON.stringify(initData));
            renderHomepage();
        }
        return ()=>{
            //_is_mounted = false;
        }

    },[initData.loaded])

    useEffect(()=>{
        
    },[])

    const loadInitData = async()=>{
    
        const response = await executeGet(api.initialData); 
        if(response.code == resCode.ok){
            let progress = { ...progressState } 
            progress.showProgress = false; 
            if(isMounted()){
                setProgressState(progress); 
                response.loaded = true;
                setInitData(response);
                props.callback(response);
            }
            //console.log(JSON.stringify(response));
        } else {
            if(response.code != resCode.error){
                if(isMounted()){
                    console.error(JSON.stringify(response)); 
                    progress.showError = true; 
                    progress.showProgress = false; 
                    progress.message = response.msg; 
                    setProgressState(progress); 
                }

            } else {
                //ANCHOR - Force Logout 
                window.postMessage({command : "FORCE_LOGOUT"}, "*");
            }
        }
    }
    const renderHomepage = ()=>{
      
        let homeData = []; 
        initData.sort.map((i,x)=>{
            if(i.type == "1") {
                //Banner - Section 
                let obj = {
                    type : "banner", 
                    data : initData.banner.data
                }
                homeData.push(obj);
            } else if(i.type == "2") {
                //Homepage - Content Section
                if(initData.homepage.total > 0 ) {
                    initData.homepage.data.map((j,k)=>{
                        j.type = "content";
                        homeData.push(j);
                    })
                } else {
                    homeData.push({
                        type : "content",
                        total : 0,
                        rows : []
                    })
                }
                
            } else if(i.type == "3") {
                //Top Reader - Section 
                //console.log(JSON.stringify(initData.top_reader.data));
                if(initData.top_reader.total > 0) {
                    let obj = {
                        type : "top-reader", 
                        data : initData.top_reader.data
                    }
                    
                    homeData.push(obj); 
                }

            } else if(i.type == "4") {
                //Recommended - Section
                if(initData.recommended.total > 0) {
                    let obj = {
                        type : "recommended",
                        data : initData.recommended.data
                    }
                    homeData.push(obj); 
                }
            } else if(i.type == "5"){
                //Category Menu 
                let obj = {
                    type : "category", 
                    data : initData.menu.data_departemen
                }
                homeData.push(obj);
            } else if(i.type == "6") {
                //Donation User 

            } else if(i.type == "7") {
                //Mitra Anak
                let obj = {
                    type : "affiliate", 
                    data : initData.mitra_anak
                }
                homeData.push(obj)
            } else if(i.type == "8"){
                //Menu Integrasi 
                if(initData.menu_integrasi.length > 0) {
                    let obj = {
                        type : "integration", 
                        data : initData.menu_integrasi
                    }
                    homeData.push(obj); 
                }
            }
        })        
        if(isMounted() && _is_mounted){
            setHomepagedata(homeData);
        } 
    }
    //ANCHOR - Render Item
    const renderItem = ({ item, index, separators})=>{
        if(item.type == "banner"){
            return(
                <Banners 
                    data={ item.data }
                    style={{marginBottom : 16}}
                />
            )
        } else if(item.type == "content") { 
            return(
                <Contents 
                    id={item.id}
                    style={{marginTop : 8 }}
                    title={item.judul_section }
                    data={item.rows}
                    onListSectionClick={ onListSectionClick }
                    navigation={props.navigation}
                />
            )
        } else if(item.type == "top-reader") { 
            return(
                <TopReader 
                    style={{marginLeft : 16, marginRight : 16, marginTop : 16, marginBottom : 16 }}
                    data={item.data}
                />
            )
        } else if(item.type == "recommended") { 
            return(
                <Recommended 
                    style={{marginLeft : 16, marginRight : 16,marginBottom : 12 }}
                    data={ item.data }
                    navigation={props.navigation}
                />
            )
        } else if(item.type == "category") { 
            
            return(
                <CategoryMenu 
                    style={{marginLeft : 16, marginRight : 16, marginBottom : 12 }}
                    data={item.data}
                    onListCategoryClick={ onListCategoryClick } 
                    onCategoryClick={(item)=>{
                        onCategoryClick(item); 
                    }}
                />
            )
        } else if(item.type == "integration") {
            return(
                <IntegratedMenu 
                    style={{marginLeft : 16, marginRight : 16 , marginBottom : 12 }}
                    data={ item.data }
                    onMoreClick={ onAppIntegrationClick }
                />
            )
        } else {
            return null; 
        }
    }

    //ANCHOR - On List Category Click 
    const onListCategoryClick = ()=>{
        
        let activeState = { ...pageState }
        activeState.showHome = false;
        activeState.showCategory = true; 
        activeState.showSectionContent = false; 
        activeState.categoryId = ""; 
        activeState.categoryTitle = "";
        activeState.showIntegratedApp = false;
        setPageState(activeState)
        

    }
    //ANCHOR - On Category Click
    const onCategoryClick = (item)=>{
        
        let id_kategori = item.id_kategori; 
        let title_kategori = item.nama_kategori;
        let activeState = { ...pageState }

        activeState.showHome = false;
        activeState.showCategory = true; 
        activeState.showSectionContent = false; 
        activeState.categoryId = id_kategori; 
        activeState.categoryTitle = title_kategori;
        activeState.showIntegratedApp = false;
        setPageState(activeState)
        
    }

    const onBackClick = (which)=>{
        if(which == "home") {
            let activeState = {...pageState }
            activeState.showHome = true;
            activeState.showCategory = false; 
            activeState.showSectionContent = false; 
            activeState.showIntegratedApp = false;
            setPageState(activeState)
        }
    }

    const onListSectionClick = (id, title)=>{
        let activeState = { ...pageState }
        activeState.showHome = false;
        activeState.showCategory = false; 
        activeState.showSectionContent = true; 
        activeState.showIntegratedApp = false;
        activeState.sectionId = id; 
        activeState.sectionTitle = title; 
        setPageState(activeState)


    }

    //ANCHOR - ON APP INTEGRATION CLICK 
    const onAppIntegrationClick = ()=>{
        //console.log("On App Integration Click");
        let activeState = { ...pageState }
        activeState.showHome = false;
        activeState.showCategory = false; 
        activeState.showSectionContent = false; 
        activeState.showIntegratedApp = true;
        setPageState(activeState)
       
    }

    //ANCHOR - MAIN VIEW RENDER
    return(
        <View style={{flex : 1}}>
            {
                progressState.showProgress && 
                <Progress 
                    text={ progressState.message }
                />
            }
            {
                (!progressState.showProgress && homepageData.length > 0 && pageState.showHome && isMounted()) &&
                <FlatList 
                    keyExtractor={(i, x)=>"idx_"+x} 
                    data={homepageData}
                    renderItem={ renderItem }
                    showsVerticalScrollIndicator={false}
                />
            }
            {
                (!progressState.showProgress && homepageData.length > 0 && pageState.showCategory) &&
                <Categories 
                    onBackClick={()=>{ onBackClick("home")}}
                    activeId={ pageState.categoryId }
                    activeTitle={ pageState.categoryTitle }
                    data={initData.menu.data_departemen}
                    navigation={props.navigation}
                />
            }
            {
                (!progressState.showProgress && homepageData.length > 0 && pageState.showSectionContent) &&
                <SectionContent 
                    onBackClick={()=>{ onBackClick("home")}}
                    id={ pageState.sectionId }
                    title={ pageState.sectionTitle }
                    navigation={props.navigation}
                />
            }
            {
                (!progressState.showProgress && homepageData.length > 0 && pageState.showIntegratedApp) &&
                <AppIntegration
                    onBackClick={()=>{ onBackClick("home")}}
                    navigation={props.navigation}
                />
            }
            <ErrorDialog 
                open={ progressState.showError }
                message={ progressState.message}
                label={"OK"}
                onClick={()=>{
                    let progress = { ...progressState} 
                    progress.showError = false; 
                    setProgressState(progress); 
                }}
            />
            


        </View>
    )

}

export { Home }