import React, { useState, useEffect, useRef } from 'react'; 
import { View, StyleSheet } from 'react-native'; 
import { Label } from '../components/FormComponents'; 
import { Progress } from '../components/DialogComponents';
import { executeGet, executePost, api, resCode } from '../utilities/Network';
import { createHeaders, resetHeaders, saveHeaders } from '../utilities/System';
import { useSelector, useDispatch } from 'react-redux'
import { setClientConfiguration, parameters, setUserParams , setClientParams} from '../reducers/parameterSlice';

const URLParams = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
}); 

var directReadFromMac = false; 

const Splash = (props)=>{
    const [firstLoad, setFirstLoad] = useState(true); 
    const [loading, setLoading ] = useState(true); 
    const [logoURL, setLogoURL] = useState("-"); 
    const clientParams = useSelector(parameters)
    const dispatch = useDispatch();
    var _client_params ;
    var params;
    
    const hasJWTToken = useRef(false); 
    const jwtToken = useRef(); 
    const directReadRef = useRef(false);
    const readTokenRef = useRef("");

    useEffect(()=>{
        if(firstLoad){
            setFirstLoad(false);            
        } else {
            window.electron.receiveMessage("INIT_PARAMS", async(_params)=>{
                //console.log("INIT PARAMS"); 
                params = _params;
                await createHeaders(params);
                //NOTE - Untuk mendeteksi apakah ini dijalankan dari electron app /jalur Windows
                // if(URLParams.t != undefined){
                //     let paramsToken = URLParams.t; 
                //     verifyAppToken(paramsToken); 
                // } else {
                    setTimeout(()=>{
                        console.log("Check App Params");
                        checkApp(params);
                    }, 1000)
                //}
            })

            window.electron.receiveMessage("LOGIN_TOKEN", (_params)=>{
                hasJWTToken.current = true; 
                jwtToken.current = _params.token; 
                
            })
            window.electron.receiveMessage("RECEIVE_READ_TOKEN", (params)=>{
                //Kondisi dimana dapet token baca, namun masih di splash screen 
                //console.log("RECEIVE READ TOKEN");
                directReadRef.current = true; 
                readTokenRef.current = params.token; 
            })
        }
        //console.log(JSON.stringify(clientParams))
    },[firstLoad]);
    //ANCHOR - Check App
    const checkApp = async(params)=>{
        //console.log("CHECK APP");
        let formData = new FormData();
        formData.append("kode", params.code)
        formData.append("versi", params.version)
        formData.append("os", params.os)
       
        const response = await executePost(api.checkApp, formData); 
        //console.log(JSON.stringify(response));
        if(response.code == resCode.ok){
            setLoading(false); 
            setLogoURL(response.url_logo_login); 
            dispatch(setClientConfiguration(response));
            _client_params = response;
           
            checkMitra(); 
           
        } else {
            //TODO - CHECK APP FAILED
            //console.log("Check App Failed");
           
        }
        //const response = await executeGet(api.checkAppVersion); 
        //console.log(JSON.stringify(response));
    }
    //ANCHOR - ReLogin
    const reLogin = async()=>{
        //console.log("#9 RELOGIN");
        //console.log(JSON.stringify(global.headers));
        let formData = new FormData(); 
        formData.append("id_user", global.headers.USERID) 
        const response = await executePost(api.credentials, formData); 
        //console.log("RESPONSE: ", response.code);
        if(response.code == resCode.ok){
            //console.log(JSON.stringify(clientParams)); 
            //console.log("RELOGIN OK"); 
            let newHeaders = {
                CLIENTID : global.headers.CLIENTID, 
                UUID : global.headers.UUID,  
                OS : global.headers.OS, 
                VERSIONCODE : global.headers.VERSIONCODE, 
                KEY : response.token, 
                USERNAME : response.data.username, 
                USERID : response.data.id_user,
                USERLEVEL : response.data.level, 
                MAXDAY : _client_params.max_hari, 
                MAXCONTENT : _client_params.max_konten
            }
            let userParams = {
                nama : response.data.nama, 
                url_foto : response.data.url_foto,
                troli_donasi : (response.data.hasOwnProperty("troli_donasi") ? response.data.troli_donasi : "0"),
                troli_toko : (response.data.hasOwnProperty("troli_toko") ? response.data.troli_toko : "0"), 
                profile_complete : response.data.profile_complete
            }
            dispatch(setUserParams(userParams)); 
            //setHeaderConfiguration(newHeaders); 
            saveHeaders(newHeaders); 
            
            setTimeout(()=>{
                props.navigation.reset({
                    index : 0, 
                    routes :[{ name : "Main"}]
                })
            },1000)

        } else {
            //console.log("RELOGIN FAILED");
            resetHeaders(params); 

            if(global.headers.CLIENTID == 6917) {
                //Univ brawijaya menggunakan link SSO nya sendiri
                //window.electron.sendMessage("LOGIN-UNIBRAW", { uuid : global.headers.UUID})
                setTimeout(()=>{
                    props.navigation.reset({
                        index : 0, 
                        routes :[{ name : "LoginUnibraw"}]
                    })
                },1000)
            } else {
                setTimeout(()=>{
                    props.navigation.reset({
                        index : 0, 
                        routes :[{ name : "Login"}]
                    })
                },1000)
            }
            
        }

    }
    //ANCHOR - Check Mitra
    const checkMitra = async()=>{
        //console.log("CHECK MITRA")
        const response = await executeGet(api.mitra); 
     
        if(response.code == resCode.ok){
            //console.log("GOT RESPONSE")
            //console.log(JSON.stringify(response.data));
            dispatch(setClientParams(response.data));
            if(global.headers.hasOwnProperty("KEY")){
                if(!hasJWTToken.current && !directReadRef.current){ 
                    //console.log("RELOGIN"); 
                    reLogin();
                } else if(!hasJWTToken.current && directReadRef.current){
                    //console.log("AUTO LOGIN")
                    autoLogin(readTokenRef.current);
                } else {
                    //console.log("VALIDATE TOKEN"); 
                    validateToken(jwtToken.current); 
                }
            } else {
                console.log("HAS NO KEY");
                if(hasJWTToken.current == false){
                   
                    if(!directReadRef.current) { 
                        if(global.headers.CLIENTID == 6917){
                            //Univ brawijaya menggunakan link SSO nya sendiri
                            //window.electron.sendMessage("LOGIN-UNIBRAW", { uuid : global.headers.UUID})
                            setTimeout(()=>{
                                props.navigation.reset({
                                    index : 0, 
                                    routes :[{ name : "LoginUnibraw"}]
                                })
                            },1000)
                        } else {
                            setTimeout(()=>{
                                props.navigation.reset({
                                    index : 0, 
                                    routes :[{ name : "Login"}]
                                })
                            },1000)
                        }
                    } else {
                        autoLogin(readTokenRef.current);
                    }      
                } else {
                    console.log("VALIDATE TOKEN"); 
                    validateToken(jwtToken.current); 
                }
            }
        } else {
            //TODO - Check Mitra Failed 

        }
    }
    //ANCHOR - Validate Token
    const validateToken = async(token)=>{
       
        //if(params.clientId == 2788){
            //console.log("AUTO LOGIN"); 

            const url = api.desktopAutoLogin + token; 
            const response = await executeGet(url); 
            //console.log(JSON.stringify(response)); 
            if(response.code == resCode.ok){
                
                let newHeaders = {
                    CLIENTID : global.headers.CLIENTID, 
                    UUID : global.headers.UUID,  
                    OS : global.headers.OS, 
                    VERSIONCODE : global.headers.VERSIONCODE, 
                    KEY : response.token, 
                    USERNAME : response.data.username, 
                    USERID : response.data.id_user,
                    USERLEVEL : response.data.level, 
                    MAXDAY : _client_params.max_hari, 
                    MAXCONTENT : _client_params.max_konten
                }
                let userParams = {
                    nama : response.data.nama, 
                    foto : response.data.url_foto,
                    troli_donasi : (response.data.hasOwnProperty("troli_donasi") ? response.data.troli_donasi : "0"),
                    troli_toko : (response.data.hasOwnProperty("troli_toko") ? response.data.troli_toko : "0"), 
                    profile_complete : response.data.profile_complete
                }

                // console.log("NEW HEADERS"); 
                // console.log(JSON.stringify(newHeaders)); 

                // console.log("USER PARAMS"); 
                // console.log(JSON.stringify(userParams)); 

                dispatch(setUserParams(userParams)); 
                //setHeaderConfiguration(newHeaders); 
                saveHeaders(newHeaders); 
                setTimeout(()=>{
                    props.navigation.reset({
                        index : 0, 
                        routes :[{ name : "Main"}]
                    })
                },1000)
                

            } else {
                if (global.headers.CLIENTID == 6917) {
                    //Univ brawijaya menggunakan link SSO nya sendiri
                    //window.electron.sendMessage("LOGIN-UNIBRAW", { uuid : global.headers.UUID})
                    setTimeout(()=>{
                        props.navigation.reset({
                            index : 0, 
                            routes :[{ name : "LoginUnibraw"}]
                        })
                    },1000)
                } else {
                    setTimeout(()=>{
                        props.navigation.reset({
                            index : 0, 
                            routes :[{ name : "Login"}]
                        })
                    },1000)
                }
            }
        // } else {

        // }
    }

    //NOTE - Auto Login 
    const autoLogin = async(token)=>{
        let url = api.autoLogin + token;
        const response = await executeGet(url); 
        if(response.code == 200) {
            //console.log("AUTO LOGIN SUCCESS"); 
            let data = response.data; 
            if(data.hasOwnProperty("id_konten")){
                //alert("SENDING ID KONTEN: " + data.id_konten);
                window.electron.sendMessage("SEND_ID_KONTEN", { id_konten : data.id_konten}); 
            }
            global.headers.UUID = data.uuid; 
            global.headers.KEY = data.token; 
            global.headers.USERNAME = data.username; 
            global.headers.USERLEVEL = data.level; 
            global.headers.USERID = data.id_user;
            global.headers.MAXDAY = _client_params.max_hari, 
            global.headers.MAXCONTENT = _client_params.max_konten
            await saveHeaders(global.headers);

            reLogin();


        } else {
            if(global.headers.CLIENTID == 6917){
                //Univ Brawijaya menggunakan link SSO nya sendiri
                //window.electron.sendMessage("LOGIN-UNIBRAW", { uuid : global.headers.UUID})
                setTimeout(()=>{
                    props.navigation.reset({
                        index : 0, 
                        routes :[{ name : "LoginUnibraw"}]
                    })
                },1000)
            } else {
                setTimeout(()=>{
                    props.navigation.reset({
                        index : 0, 
                        routes :[{ name : "Login"}]
                    })
                },2000)
            }
        }
    }
    //ANCHOR - Verify App Token , di launch dari electron 
    const verifyAppToken = async(token)=>{
        //alert("#3 VERIFIYING PARAMS");
        const url = api.verifyAppToken + token;
        //console.log("VERIFY PARAMS: " , url);  
        const response = await executeGet(url); 
        
        if(response.code == 200) {
            // console.log("RESPONSE OK"); 
            // console.log(JSON.stringify(response));
            let data = response.data; 
            if(data.hasOwnProperty("id_konten")){
                //alert("SENDING ID KONTEN: " + data.id_konten);
                window.electron.sendMessage("SEND_ID_KONTEN", { id_konten : data.id_konten}); 
            }

            global.headers.UUID = data.uuid; 
            global.headers.KEY = data.token; 
            global.headers.USERNAME = data.username; 
            global.headers.USERLEVEL = data.level; 
            global.headers.USERID = data.id_user;
            await saveHeaders(global.headers);
            hasJWTToken.current = false;

            //alert("#4 NEW HEADERS");
            //console.log(JSON.stringify(global.headers));
            
            setTimeout(()=>{
                //alert("#5 CHECK APP");
                checkApp(params);
            },1000)

            /*
             let newHeaders = {
                CLIENTID : global.headers.CLIENTID, 
                UUID : global.headers.UUID,  
                OS : global.headers.OS, 
                VERSIONCODE : global.headers.VERSIONCODE, 
                KEY : response.token, 
                USERNAME : response.data.username, 
                USERID : response.data.id_user,
                USERLEVEL : response.data.level, 
                MAXDAY : _client_params.max_hari, 
                MAXCONTENT : _client_params.max_konten
            }
            */
            
        } else {
            setTimeout(()=>{
                checkApp(params);
            }, 1000);
        }
        
    }   

    return(
        <View style={ styles.container }>
            {
                loading && 
                <Progress 
                    text={"Please Wait"}
                />
            }
            {
                (!loading && logoURL != "-") &&
                <img 
                    src={ logoURL }
                    style={{
                        width : 450, 
                        height : "auto"
                    }}
                />
            }
        </View>
    )
}

const styles = StyleSheet.create({
    container : {
        flex : 1,
        alignItems : "center", 
        justifyContent : "center" ,
        backgroundColor : "white"

    }
})

export { Splash }