import React, { useState } from 'react'; 
import { View, StyleSheet, Pressable, FlatList, ScrollView } from 'react-native'; 
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel'; 
import { Paper,Avatar, Typography, CardActionArea } from '@mui/material';
import { Label } from '../components/FormComponents';
import { shuffleArray, useIsMounted } from '../utilities/System';
import { setClientConfiguration, parameters, userParams } from '../reducers/parameterSlice';
import { useSelector, useDispatch } from 'react-redux'
import { ContentDetail } from './ContentDetail';


const Banners = (props)=>{
  
    const openURL = (item)=>{
        if(item.url_link.includes("http") || item.url_link.includes("https")){
            window.electron.sendMessage("OPEN_URL", item.url_link); 
        }
    }

    return(
        <View style={ props.style }>
            <Carousel
                showThumbs={false}
                showStatus={false}
            >
                {
                    props.data.map((item,index)=>(
                        <Pressable key={"banner_"+index} style={{
                            borderRadius : 12, 
                            borderWidth : 0, 
                            marginLeft : 12, 
                            marginRight : 12,
                            overflow : "hidden"
                        }}
                        onPress={()=>{ openURL(item)}}
                        
                        >
                            <img 
                                src={ item.url_banner } 
                                style={{ cursor : "pointer"}}
                                
                            />
                        </Pressable>
                    ))
                }

            </Carousel>
        </View>

    )
}

const Contents = (props)=>{
    //const isMounted = useIsMounted(); 
    const [details, setDetails ] = useState({
        show : false,
        id : "", 
        title : ""
    }); 
    
    

    const onContentClick = (item)=>{
        props.navigation.navigate("DetailPage", item); 
        // if(isMounted()){
        //     let id_konten = item.id_konten; 
        //     let judul = item.judul ; 
        //     setDetails({
        //         show : true, 
        //         id : id_konten, 
        //         title : judul
        //     })
        // }
        
    }

    const renderItem = ({ item, index, separators})=>{
        return(
            <Pressable
                key={"book_"+index}
                style={{ 
                    borderRadius : 12,
                    borderWidth : 0, 
                    marginRight : (index != props.data.length - 1 ? 12 : 0 ),
                    marginBottom : 12,
                    cursor : "pointer",
                }}
                onPress={()=>{ onContentClick(item)}}
            >
                <View 
                    style={{
                        alignItems : "center",
                        height : 300
                    }}
                >
                    <img 
                        src={ item.img_file}
                        style={{
                            marginTop : 8, 
                            width : 120, 
                            borderRadius : 12
                        }}
                    />
                    <Label 
                        size={12}
                        text={item.judul}
                        weight={"600"}
                        align={"left"}
                        style={{
                            minWidth : 120, 
                            paddingLeft : 8, 
                            paddingRight : 8, 
                            marginTop : 4, 
                            marginBottom : 4, 
                            maxWidth : 120,
                            display : "-webkit-box", 
                            WebkitBoxOrient: "vertical", 
                            WebkitLineClamp : 2, 
                            overflow : "hidden", 
                            textOverflow : "ellipsis"
                        }}
                    />
                    <Label 
                        size={12}
                        text={item.penulis}
                        weight={"600"}
                        color={"#2980b9"}
                        align={"left"}
                        textAlign={"left"}
                        style={{
                            minWidth : 120, 
                            paddingLeft : 8, 
                            paddingRight : 8,  
                            marginTop : 4, 
                            marginBottom : 12, 
                            maxWidth : 120,
                            display : "-webkit-box", 
                            WebkitBoxOrient: "vertical", 
                            WebkitLineClamp : 2, 
                            overflow : "hidden", 
                            textOverflow : "ellipsis",
                          
                        }}
                    />
                    <View 
                        style={{
                            padding : 4, 
                            borderRadius : 8, 
                            borderWidth : 0.3,
                            borderColor : "#130f40", 
                            alignItems : "center", 
                            justifyContent : "center",
                            position : "absolute", 
                            bottom : 0
                    }}>
                        <Label 
                            size={10}
                            weight={"600"}
                            color={"#130f40"}
                            text={"Stok: "+item.stok + "/" + item.qty}
                            textAlign={"center"}
                        />
                    </View>

                </View>

            </Pressable>
        )
    }
    
    return(
        <View style={ props.style }>
            <View style={{ flexDirection : "row", alignItems : "center", 
                paddingLeft : 16, 
                paddingRight : 16 }}>
                <Label 
                    size={14}
                    text={props.title}
                    weight={"600"}
                    style={{flex : 1}}
                />
                {
                    props.data.length > 0 && 
                    <Label
                        size={12}
                        color={"#2980b9"}
                        weight={"600"}
                        style={{ cursor : "pointer"}}
                        text={"selengkapnya"}
                        onClick={()=>{ props.onListSectionClick(props.id, props.title)} }
                    />
                }
               

            </View>
            {
                props.data.length > 0 && 
                <FlatList 
                    keyExtractor={(i,x)=>"content_id_"+i.id_konten+"_"+x}
                    contentContainerStyle={{marginLeft : 16, marginRight : 16 , marginTop : 12 }}
                    horizontal={ true }
                    data={props.data}
                    renderItem={renderItem }
                    showsHorizontalScrollIndicator={false}
                />
            }
            {
                props.data.length == 0 && 
                <View>
                    <Label 
                        size={14}
                        text={"Konten belum tersedia"}
                        style={{marginLeft : 16}}
                        weight={"600"}
                    />
                    <Label 
                        size={14} 
                        weight={"400"}
                        text={"Silahkan hubungi admin perpustakaan anda"}
                        style={{marginLeft : 16}}
                    />
                </View>
            }
            {/* <ContentDetail 
                open={ details.show }
                start={ details.show }
                 title={ details.title }
                onClose={()=>{
                    let _details = {...details}
                       nnnnnmm      b   
                    _details.show = false; 
                    setDetails(_details);
                }}
                navigation={props.navigation}
            /> */}
            

        </View>
    )
}

//ANCHOR - Top Reader 
const TopReader = (props)=>{

    const renderItem = ({ item, index, separators})=>{
        return(
            <View
                key={"reader_idx_"+index}
                style={{
                    marginBottom : 4, 
                    marginRight : (index != props.data.length - 1 ? 8 : 0),
                    width : 140,
                    borderRadius : 12
                }}
            >
                <View style={{alignItems : "center"}}>
                    <Avatar 
                        sx={{
                            width : 64,
                            height : 64
                        }}
                        style={{
                            marginTop : 12 
                        }}
                        src={ item.photo }
                    />
                    <Label
                        size={12}
                        weight={"600"}
                        text={item.nama}
                        textAlign={"center"}
                        style={{
                            minWidth : 120,
                            marginTop : 8, 
                            display : "-webkit-box", 
                            WebkitBoxOrient: "vertical", 
                            WebkitLineClamp : 1, 
                            overflow : "hidden", 
                            textOverflow : "ellipsis"
                        }}
                    />
                    <Label
                        size={10}
                        weight={"600"}
                        color={"#2980b9"}
                        text={item.durasi}
                        textAlign={"center"}
                        style={{
                            minWidth : 120,
                            marginTop : 4, 
                            display : "-webkit-box", 
                            WebkitBoxOrient: "vertical", 
                            WebkitLineClamp : 2, 
                            overflow : "hidden", 
                            textOverflow : "ellipsis"
                        }}
                    />
                    <Label
                        size={12}
                        weight={"400"}
                        color={"#2c3e50"}
                        text={item.jumlah_konten + " judul"}
                        textAlign={"center"}
                        style={{
                            minWidth : 120,
                            marginTop : 4, 
                            marginBottom : 12,
                            display : "-webkit-box", 
                            WebkitBoxOrient: "vertical", 
                            WebkitLineClamp : 2, 
                            overflow : "hidden", 
                            textOverflow : "ellipsis"
                        }}
                    />

                </View>

            </View>
        )

    }
    return(
        <View style={props.style}>
            <Label
                size={14}
                text={"Pembaca Terbaik"}
                weight={"600"} 

            />
            <FlatList 
                contentContainerStyle={{
                    marginTop : 12
                }}
                keyExtractor={(i,x)=>"index_"+x} 
                data={props.data}
                renderItem={ renderItem }
                horizontal={true}
                showsHorizontalScrollIndicator={ false }
            />
        </View>

    )
}
//ANCHOR - Recommended
const Recommended = (props)=>{
    const isMounted = useIsMounted();
    const [details, setDetails ] = useState({
        show : false,
        id : "", 
        title : ""
    }); 
    

    const onContentClick = (item)=>{
        if(isMounted()){
            props.navigation.navigate("DetailPage", item); 
            // let id_konten = item.id_konten; 
            // let judul = item.judul ; 
            // setDetails({
            //     show : true, 
            //     id : id_konten, 
            //     title : judul
            // })
        }
        
    }

    let mod = props.data.length % 2; 
    let data = [...props.data];
    if(mod > 0 ) {
        let add_factor = 2 - mod; 
        for(let x = 0;x < add_factor;x++){
            data.push({ dummy : "x"}); 
        }
    } 
    
    const renderItem = ({ item, index, separators})=>{
        if(!item.hasOwnProperty("dummy")){
           
            return(
                <Pressable style={{
                    cursor : "pointer", 
                    flex : 1, 
                    flexDirection : "row", marginBottom : 12 }}
                    onPress={()=>{ onContentClick(item)}}
                >
                    <img 
                        src={ item.img_file }
                        style={{
                            width : 120, 
                            borderRadius : 12
                        }}
                    />

                    <View style={{flex : 1, marginLeft : 16 }}>
                        <Label 
                            size={14}
                            weight={"600"}
                            text={item.judul }
                        />
                        <Label
                            size={12}
                            weight={"600"}
                            color={"#2980b9"}
                            text={item.nama}
                            style={{marginTop : 8 }}
                        />
                    
                        <Typography
                            fontFamily={"Poppins"}
                            fontSize={12}
                            fontWeight={"400"}
                            component={"span"}
                            style={{
                                marginRight : 12,   
                                display : "-webkit-box", 
                                WebkitBoxOrient: "vertical", 
                                WebkitLineClamp : 5, 
                                overflow : "hidden", 
                                textOverflow : "ellipsis"
                            }}
                        >
                            <div
                                dangerouslySetInnerHTML={{ __html: item.sinopsis}}
                            />
                        </Typography>
                    </View>
                </Pressable>
            )
        } else {
            return(
                <View style={{flex : 1}}>

                </View>
            )
        }
    }
    
    return(
        <View style={ props.style }>
            <Label
                size={14}
                weight={"600"}
                text={"Rekomendasi"}
                style={{marginLeft : 0, marginBottom : 12  }}
            />
            <FlatList 
                contentContainerStyle={{marginLeft : 16, marginRight : 16 }}
                keyExtractor={(i,x)=>"recom_item_"+x}
                numColumns={2}
                data={ data }
                renderItem={ renderItem }
                showsVerticalScrollIndicator={false}
            />
            <ContentDetail 
                open={ details.show }
                start={ details.show }
                id={ details.id }
                title={ details.title }
                onClose={()=>{
                    let _details = {...details}
                    _details.show = false; 
                    setDetails(_details);
                }}
                navigation={props.navigation}
            />
        </View>
    )
}


const CategoryMenu = (props)=>{
    const clientParams = useSelector(parameters); 
    let data = props.data;

    let books_data = data[0].data_kategori; 
    //var categories = shuffleArray(books_data);
    var categories = books_data;
    if(categories.length > 18) {
        let _data = []; 
        for(let i = 0;i < 18 ;i++){
            _data.push(categories[i])
        }
        categories = _data;
    } 
    let mod = categories.length % 6 ; 
    if(mod > 0) {
        let add_factor = 6 - mod; 
        for(let j =0;j<add_factor;j++){
            categories.push({dummy : j}); 
        }
    }

    //console.log(JSON.stringify(categories));


    const renderItem = ({item, index, separators})=>{
     
        if(!item.hasOwnProperty("dummy")){
            return(
                <CardActionArea
                    style={{
                        borderRadius : 12,
                        cursor : "pointer",
                        borderColor :  clientParams.warna_header,
                        borderWidth : 0.5,
                        margin : 4 ,
                    }}
                    onClick={()=>{ 
                        props.onCategoryClick(item)
                    }}
                >
                    <View
                        style={{
                            flex : 1,
                            borderRadius : 12, 
                            borderColor :  clientParams.warna_header,
                            borderWidth : 0.5,
                            alignItems : "center", 
                            justifyContent : "center",
                        
                            minHeight : 42,
                        }}
                    >
                            
                        <Label
                            size={12}
                            weight={"400"}
                            color={clientParams.warna_header}
                            text={item.nama_kategori}
                            textAlign={"center"}
                            style={{
                                marginLeft : 8, 
                                marginRight : 8, 
                                display : "-webkit-box", 
                                WebkitBoxOrient: "vertical", 
                                WebkitLineClamp : 2, 
                                overflow : "hidden", 
                                textOverflow : "ellipsis"
                            }}
                        />
                            
                        
                    </View>
                </CardActionArea>
            )
        } else {
            return(
                <View style={{flex : 1}}></View>
            )
        }
    }
    return( 
        <View style={props.style }>
            {
                books_data.length > 0 && 
                <>
                    <View style={{flexDirection : "row", alignItems : "center"}}>
                        <Label
                            size={14}
                            style={{marginBottom : 12, flex : 1}}
                            weight={"600"}
                            text={"Kategori Buku"}
                        />
                        <Label 
                            size={12}
                            weight={"600"}
                            color={"#2980b9"}
                            style={{ cursor : "pointer"}}
                            text={"Selengkapnya"}
                            onClick={ props.onListCategoryClick }
                        />
                    </View>
                    <FlatList 
                        keyExtractor={(i,x)=>"category_"+x}
                        data={ categories }
                        renderItem={renderItem}
                        numColumns={6}
                    />  
                </>
            }
        </View>
    )
}

const IntegratedMenu = (props)=>{
    const clientParams = useSelector(parameters); 
    const onItemClick = (item)=>{
        //console.log("Item Integrated");
        //console.log(JSON.stringify(item)); 
        if(item.hasOwnProperty("url")){
            window.electron.sendMessage("OPEN_URL", item.url);
        } else if(item.hasOwnProperty("android_uri")) {
            try {
                let uri = new URL(item.android_uri); 
                let url = uri.searchParams.get("url"); 
                //console.log(url);
                window.electron.sendMessage("OPEN_URL", url);

            } catch(err){
                console.log("ERROR: " , err.message);
            }
        }
    }
    const renderItem = ({item, index, separators})=>{
        return(
            <Pressable
                key={"menu_"+index}
                style={{ 
                    borderRadius : 12,
                    borderWidth : 0, 
                    marginRight : (index != props.data.length - 1 ? 12 : 0 ),
                    marginBottom : 12,
                    cursor : "pointer",
                }}
                onPress={()=>{ onItemClick(item)}}
            >
                <View 
                    style={{
                        alignItems : "center",
                        
                    }}
                >
                    <img 
                        src={ item.url_icon}
                        style={{
                            marginTop : 8, 
                            width : 140, 
                            borderRadius : 12
                        }}
                    />
                    <Label 
                        size={14}
                        text={item.nama}
                        weight={"400"}
                        align={"left"}
                        style={{
                            minWidth : 120, 
                            paddingLeft : 8, 
                            paddingRight : 8, 
                            marginTop : 4, 
                            marginBottom : 4, 
                            maxWidth : 120,
                            display : "-webkit-box", 
                            WebkitBoxOrient: "vertical", 
                            WebkitLineClamp : 2, 
                            overflow : "hidden", 
                            textOverflow : "ellipsis"
                        }}
                    />
                </View>
            </Pressable>
        )

    }

    return(
        <View style={props.style }>
            <View style={{flexDirection : "row", alignItems : "center"}}>
                <Label
                    size={14}
                    style={{marginBottom : 12, flex : 1}}
                    weight={"600"}
                    text={"Aplikasi Terintegrasi"}
                />
                <Label 
                    size={12}
                    weight={"600"}
                    color={"#2980b9"}
                    style={{ cursor : "pointer"}}
                    text={"selengkapnya"}
                    onClick={ props.onMoreClick }
                />
            </View>
            <FlatList 
                keyExtractor={(item, index)=>"app_index_"+index}
                renderItem={ renderItem } 
                data={ props.data }
                showsHorizontalScrollIndicator={ false }
                horizontal={ true }
            />
        </View>
    )
}

//TODO - Affiliated Libraries
const AffiliatedLibraries = (props)=>{
    const clientParams = useSelector(parameters); 
    const renderItem = ({item, index, separators})=>{


    }


    return(
        <View style={props.style }>
            <View style={{flexDirection : "row", alignItems : "center"}}>
                <Label
                    size={14}
                    style={{marginBottom : 12, flex : 1}}
                    weight={"600"}
                    text={"Perpustakaan Afiliasi"}
                />
                {/* <Label 
                    size={12}
                    weight={"600"}
                    color={"#2980b9"}
                    style={{ cursor : "pointer"}}
                    text={"Selengkapnya"}
                /> */}
            </View>
            <FlatList 
                keyExtractor={(item, index)=>"affiliate_index_"+index}
                renderItem={ renderItem } 
                data={ props.data }
                showsHorizontalScrollIndicator={ false }
                horizontal={ true }
            />
        </View>

    )

}

export { Banners, Contents, TopReader, Recommended, CategoryMenu, IntegratedMenu }