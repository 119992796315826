import React, { useState, useEffect, useRef } from 'react'; 
import { View, StyleSheet } from 'react-native'; 
import { Label, TextField, PushButton } from '../../components/FormComponents'; 
import { Progress, ProgressDialog, ErrorDialog, SuccessDialog } from '../../components/DialogComponents';
import { executeGet, executePost, api, resCode } from '../../utilities/Network';
import { createHeaders, saveHeaders } from '../../utilities/System';
import { useSelector, useDispatch } from 'react-redux'
import { setClientConfiguration,setHeaderConfiguration, parameters, setUserParams } from '../../reducers/parameterSlice';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { ForgotPasswordDialog  } from '../../components/DialogComponents';


const LoginUnibraw = (props)=>{
    const clientParams = useSelector(parameters); 
    const dispatch = useDispatch(); 
    const initProgress = {
        showProgress : false, 
        message : "Harap Tunggu", 
        showError : false, 
        showSuccess : false
    }
    const [progressParams, setProgressParams]= useState(initProgress) 
    const onLoginClick = ()=>{
        try {
            window.electron.sendMessage("LOGIN_SSO", { dummy : 0});
        } catch(err){

        }
    }

    useEffect(()=>{
        window.electron.receiveMessage("AUTH_SSO", (params)=>{
            const authCode = params.authCode;
            let _initProgress = { ...progressParams}
            _initProgress.showProgress = true; 
            setProgressParams(_initProgress); 
            executeSSOLogin(authCode);
        
        })
        return ()=>{

        }
    },[])

    const executeSSOLogin = async(authCode)=>{
        let formData = new FormData(); 
        formData.append("id_mitra", 6917) 
        formData.append("auth_code", authCode); 
      
        const response = await executePost(api.oauthSSO, formData); 
        let _initProgress = { ...progressParams}
        _initProgress.showProgress = false; 
        setProgressParams(_initProgress); 
        if(response.code == resCode.ok){
            //console.log(JSON.stringify(response));
            let newHeaders = {
                CLIENTID : global.headers.CLIENTID, 
                UUID : global.headers.UUID,  
                OS : global.headers.OS, 
                VERSIONCODE : global.headers.VERSIONCODE, 
                KEY : response.token, 
                USERNAME : response.data.username, 
                USERID : response.data.id_user,
                USERLEVEL : response.data.level, 
                MAXDAY : clientParams.max_hari, 
                MAXCONTENT : clientParams.max_konten
            }
            let userParams = {
                nama : response.data.nama, 
                url_foto : response.data.url_foto,
                troli_donasi : (response.data.hasOwnProperty("troli_donasi") ? response.data.troli_donasi : "0"),
                troli_toko : (response.data.hasOwnProperty("troli_toko") ? response.data.troli_toko : "0"), 
                profile_complete : response.data.profile_complete
            }
            dispatch(setUserParams(userParams)); 
            //setHeaderConfiguration(newHeaders); 
            saveHeaders(newHeaders); 
            setTimeout(()=>{
                props.navigation.reset({
                    index : 0, 
                    routes :[{ name : "Main"}]
                })
            },1000)
        }

    }
    return(
        <View style={ styles.container} tabIndex={0}>
            <img
                src={ clientParams.url_logo_login}
                style={{
                    width : 350, 
                    height : "auto"
                }}
            />
            <Label 
                weight={"600"}
                size={16}
                text={"Selamat datang di e-Library Universitas Brawijaya."}
                style={{
                    marginTop : 0,  
                    marginLeft : 16, 
                    marginBottom : 0
                }}
            />
            <Label 
                weight={"400"}
                size={14}
                text={"Temukan jutaan koleksi buku digital yang mendukung pembelajaran dan penelitian Anda"}
            />
            <Label
                weight={"400"}
                size={14}
                text={"Selamat menjelajahi dunia pengetahuan!"}
            />
            <PushButton 
                text={"Masuk"} 
                textWeight={"400"}
                variant="contained"
                style={{
                    marginTop : 16,
                    width : 320,
                    marginLeft : 16, 
                    marginRight : 16,
                    borderRadius : 8
                }}
                onClick={ onLoginClick }
            />
            <ProgressDialog 
                open={ progressParams.showProgress }
                message={ progressParams.message }
            />


        </View>
    )
}

const styles = StyleSheet.create({
    container : {
        flex : 1,
        backgroundColor : "white",
        flexDirection : "column", 
        alignItems : "center", 
        justifyContent : "center"

    },
    leftSide : {
        flex : 0.7, 
        borderRightWidth : 0.3, 
        borderColor : "silver",
        alignItems : "center", 
        justifyContent : "center"
    },
    rightSide : {
        flex : 0.3, 
        backgroundColor : "white"
    }
})

export { LoginUnibraw }