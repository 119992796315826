import * as React from "react"
import Svg, { G,Path, Circle } from "react-native-svg"

const CardPlaceholder = () => (
    <Svg
      height={ 120 }
      width={ 120}
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <Path
        style={{
          fill: "#A2A9AF",
        }}
        d="M500.416,404.272c0,6.371-5.213,11.584-11.584,11.584H23.167c-6.371,0-11.584-5.213-11.584-11.584 V107.729c0-6.371,5.213-11.584,11.584-11.584h465.665c6.371,0,11.584,5.213,11.584,11.584V404.272z"
      />
      <Circle
        style={{
          fill: "#FFE1D6",
        }}
        cx={139.005}
        cy={216.882}
        r={28.959}
      />
      <Path
        style={{
          fill: "#FFFFFF",
        }}
        d="M150.588,267.317l-11.584,15.492l-11.584-15.492c-26.436,5.367-46.335,28.738-46.335,56.758h115.837 C196.923,296.055,177.025,272.684,150.588,267.317z"
      />
      <Path d="M139.005,250.208c22.355,0,40.543-18.188,40.543-40.543s-18.188-40.543-40.543-40.543s-40.543,18.188-40.543,40.543 S116.649,250.208,139.005,250.208z M139.005,192.29c9.581,0,17.376,7.795,17.376,17.376c0,9.581-7.795,17.376-17.376,17.376 s-17.376-7.795-17.376-17.376C121.629,200.084,129.424,192.29,139.005,192.29z" />
      <Path d="M81.086,342.878h115.837c6.398,0,11.584-5.186,11.584-11.584c0-38.324-31.179-69.502-69.502-69.502 s-69.502,31.179-69.502,69.502C69.502,337.692,74.688,342.878,81.086,342.878z M139.005,284.959 c21.55,0,39.712,14.789,44.868,34.751H94.136C99.292,299.748,117.454,284.959,139.005,284.959z" />
      <Path d="M488.833,84.561H23.167C10.393,84.561,0,94.954,0,107.729v296.543c0,12.775,10.393,23.167,23.167,23.167h465.665 c12.775,0,23.167-10.393,23.167-23.167V107.729C512,94.954,501.607,84.561,488.833,84.561z M488.833,404.272H23.167V107.729h465.665 V404.272z" />
      <Path d="M266.425,267.584h164.489c6.397,0,11.584-5.186,11.584-11.584c0-6.398-5.187-11.584-11.584-11.584H266.425 c-6.397,0-11.584,5.186-11.584,11.584C254.842,262.398,260.029,267.584,266.425,267.584z" />
      <Path d="M266.425,325.502h23.167c6.397,0,11.584-5.186,11.584-11.584c0-6.398-5.187-11.584-11.584-11.584h-23.167 c-6.397,0-11.584,5.186-11.584,11.584C254.842,320.316,260.029,325.502,266.425,325.502z" />
      <Path d="M430.914,302.335h-23.167c-6.397,0-11.584,5.186-11.584,11.584c0,6.398,5.187,11.584,11.584,11.584h23.167 c6.397,0,11.584-5.186,11.584-11.584C442.498,307.521,437.311,302.335,430.914,302.335z" />
      <Path d="M360.253,302.335h-23.167c-6.397,0-11.584,5.186-11.584,11.584c0,6.398,5.187,11.584,11.584,11.584h23.167 c6.397,0,11.584-5.186,11.584-11.584C371.837,307.521,366.65,302.335,360.253,302.335z" />
      <Path d="M266.425,209.665h82.244c6.397,0,11.584-5.186,11.584-11.584c0-6.398-5.187-11.584-11.584-11.584h-82.244 c-6.397,0-11.584,5.186-11.584,11.584C254.842,204.479,260.029,209.665,266.425,209.665z" />
    </Svg>
)

const ErrorMark = (props) => (
    <Svg
      fill="#d63031"
      height={ props.height }
      width={ props.width }
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <G>
        <G>
          <G>
            <Path d="M437.016,74.984c-99.979-99.979-262.075-99.979-362.033,0.002c-99.978,99.978-99.978,262.073,0.004,362.031 c99.954,99.978,262.05,99.978,362.029-0.002C536.995,337.059,536.995,174.964,437.016,74.984z M406.848,406.844 c-83.318,83.318-218.396,83.318-301.691,0.004c-83.318-83.299-83.318-218.377-0.002-301.693 c83.297-83.317,218.375-83.317,301.691,0S490.162,323.549,406.848,406.844z" />
            <Path d="M361.592,150.408c-8.331-8.331-21.839-8.331-30.17,0l-75.425,75.425l-75.425-75.425c-8.331-8.331-21.839-8.331-30.17,0 s-8.331,21.839,0,30.17l75.425,75.425L150.43,331.4c-8.331,8.331-8.331,21.839,0,30.17c8.331,8.331,21.839,8.331,30.17,0 l75.397-75.397l75.419,75.419c8.331,8.331,21.839,8.331,30.17,0c8.331-8.331,8.331-21.839,0-30.17l-75.419-75.419l75.425-75.425 C369.923,172.247,369.923,158.74,361.592,150.408z" />
          </G>
        </G>
      </G>
    </Svg>
)
  

const SuccessMark = (props)=>(
  <Svg
    fill="#00b894"
    height={ props.height }
    width={ props.width }
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
  >
    <G>
      <G>
        <G>
          <Path d="M437.016,74.984c-99.979-99.979-262.075-99.979-362.033,0.002c-99.978,99.978-99.978,262.073,0.004,362.031 c99.954,99.978,262.05,99.978,362.029-0.002C536.995,337.059,536.995,174.964,437.016,74.984z M406.848,406.844 c-83.318,83.318-218.396,83.318-301.691,0.004c-83.318-83.299-83.318-218.377-0.002-301.693 c83.297-83.317,218.375-83.317,301.691,0S490.162,323.549,406.848,406.844z" />
          <Path d="M368.911,155.586L234.663,289.834l-70.248-70.248c-8.331-8.331-21.839-8.331-30.17,0s-8.331,21.839,0,30.17 l85.333,85.333c8.331,8.331,21.839,8.331,30.17,0l149.333-149.333c8.331-8.331,8.331-21.839,0-30.17 S377.242,147.255,368.911,155.586z" />
        </G>
      </G>
    </G>
  </Svg>
)

const QuestionMark = (props) => (
  <Svg
    height={ props.height }
    swidth={ props.width }
    viewBox="0 0 15 15"
    fill={ props.fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <G id="SVGRepo_bgCarrier" strokeWidth={0} />
    <G
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={props.fill }
    />
    <G id="SVGRepo_iconCarrier">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.877075 7.49972C0.877075 3.84204 3.84222 0.876892 7.49991 0.876892C11.1576 0.876892 14.1227 3.84204 14.1227 7.49972C14.1227 11.1574 11.1576 14.1226 7.49991 14.1226C3.84222 14.1226 0.877075 11.1574 0.877075 7.49972ZM7.49991 1.82689C4.36689 1.82689 1.82708 4.36671 1.82708 7.49972C1.82708 10.6327 4.36689 13.1726 7.49991 13.1726C10.6329 13.1726 13.1727 10.6327 13.1727 7.49972C13.1727 4.36671 10.6329 1.82689 7.49991 1.82689ZM8.24993 10.5C8.24993 10.9142 7.91414 11.25 7.49993 11.25C7.08571 11.25 6.74993 10.9142 6.74993 10.5C6.74993 10.0858 7.08571 9.75 7.49993 9.75C7.91414 9.75 8.24993 10.0858 8.24993 10.5ZM6.05003 6.25C6.05003 5.57211 6.63511 4.925 7.50003 4.925C8.36496 4.925 8.95003 5.57211 8.95003 6.25C8.95003 6.74118 8.68002 6.99212 8.21447 7.27494C8.16251 7.30651 8.10258 7.34131 8.03847 7.37854L8.03841 7.37858C7.85521 7.48497 7.63788 7.61119 7.47449 7.73849C7.23214 7.92732 6.95003 8.23198 6.95003 8.7C6.95004 9.00376 7.19628 9.25 7.50004 9.25C7.8024 9.25 8.04778 9.00601 8.05002 8.70417L8.05056 8.7033C8.05924 8.6896 8.08493 8.65735 8.15058 8.6062C8.25207 8.52712 8.36508 8.46163 8.51567 8.37436L8.51571 8.37433C8.59422 8.32883 8.68296 8.27741 8.78559 8.21506C9.32004 7.89038 10.05 7.35382 10.05 6.25C10.05 4.92789 8.93511 3.825 7.50003 3.825C6.06496 3.825 4.95003 4.92789 4.95003 6.25C4.95003 6.55376 5.19628 6.8 5.50003 6.8C5.80379 6.8 6.05003 6.55376 6.05003 6.25Z"
        fill={ props.fill }
      />
    </G>
  </Svg>
)

export { CardPlaceholder, ErrorMark, SuccessMark, QuestionMark }