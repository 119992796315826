


const api = {
    checkApp : "https://kubuku.id/api/wl/checkAppVersion",  
    login : "https://kubuku.id/api/wl/executeLogin",  
    forgotPassword : "https://kubuku.id/api/wl/forgotPassword",
    registration : "https://kubuku.id/api/wl/simpleRegistration", 
    credentials : "https://kubuku.id/api/wl/checkLoginCredentials",  
    initialData : "https://kubuku.id/api/wl/initialData",  
    sectionDetail : "https://kubuku.id/api/wl/listHomepageDetail",
    categoryDetail : "https://kubuku.id/api/wl/listContentCategory",  
    subCategoryDetail : "https://kubuku.id/api/wl/listContentSubCategory",
    news : "https://kubuku.id/api/wl/news", 
    newsDetail : "https://kubuku.id/api/wl/newsDetail",
    searching : "https://kubuku.id/api/wl/searching", 
    searchingKalimat : "https://kubuku.id/api/wl/searchingKalimat",
    detailContent : "https://kubuku.id/api/wl/detailKonten",
    directLogin : "https://kubuku.id/api/wl/directLogin",
    directRegistration : "https://kubuku.id/api/wl/directRegistration",
    kontenNonKoleksi : "https://kubuku.id/api/wl/kontenNonKoleksi",
    bookRack : "https://kubuku.id/api/wl/storeUserKonten",
    integratedAppList : "https://kubuku.id/api/wl/menuIntegrasi",
    checkRate : "https://kubuku.id/api/wl/cekRate/",
    borrowBook : "https://kubuku.id/api/wl/requestPinjam", 
    bookReturn : "https://kubuku.id/api/wl/returnPinjam",
    storeContent : "https://kubuku.id/api/wl/kontenNonKoleksi",
    requestBaca : "https://kubuku.id/api/wl/requestBaca",
    requestContent : "https://kubuku.id/api/konten/requestContent/",
    closeBook : "https://kubuku.id/api/wl/bookReturn",
    saveAnnotation : "https://kubuku.id/api/wl/saveAnotasi",
    updateReadStatus : "https://kubuku.id/api/wl/updateReadStatus",
    checkPinjam : "https://kubuku.id/api/wl/checkPinjam",
    setTroli : "https://kubuku.id/api/wl/setTroli",
    deleteTroli : "https://kubuku.id/api/wl/deleteTroli",
    listTroli : "https://kubuku.id/api/wl/listTroli",
    mitra : "https://kubuku.id/api/wl/mitra",
    paymentTypes : "https://kubuku.id/api/wl/getPaymentType",
    checkOutTokenDonasiPoin : "https://kubuku.id/api/wl/checkOutTokenDonasiPoin",
    cartList : "https://kubuku.id/api/wl/listTroli",
    deleteCart : "https://kubuku.id/api/wl/deleteTroli",
    voucherCheck : "https://kubuku.id/api/wl/cekKodeVoucher2",
    checkOutToken : "https://kubuku.id/api/wl/checkOutToken",
    transactionList : "https://kubuku.id/api/wl/transactionList",
    transactionDetail : "https://kubuku.id/api/wl/listPODetail",
    pendingTransactionCount : "https://kubuku.id/api/wl/pendingTransactionCount",
    copyTextInfo : "https://kubuku.id/api/wl/copyTextInfo",
    logout : "https://kubuku.id/api/wl/executeLogout",
    aauLogin : "https://kubuku.id/api/wl/aauLogin/",
    submitBookRequest : "https://kubuku.id/api/wl/submitBookRequest",
    updateAvatar : "https://kubuku.id/api/wl/updatePhotoProfile", 
    verifyAppToken : "https://kubuku.id/api/wl/content/token/",  //untuk verifikasi token dari external web, 
    autoLogin : "https://kubuku.id/api/wl/content/autoLogin/", 
    desktopAutoLogin : "https://kubuku.id/api/wl/autoLogin/", 
    oauthSSO : "https://kubuku.id/api/wl/oauthSSO"
}

const resCode =  {
    ok : 200, 
    empty : 404, 
    error : 500
}



const executePost = async(url, formData )=>{
    try {
        let postData = {
            method : 'post', 
            headers : global.headers, 
            body : formData
        }
        const response = await fetch(url, postData);
        const json = await response.text();
        //console.log(json);
        return JSON.parse(json);

    } catch(error){
        
        let errorObj = {
            code : 500, 
            msg : 'Network Error'
        }
        //console.log(error),
        return errorObj;
    }
}


const executeGet = async(url)=>{
    try {
        let postData = {
            method : 'get', 
            headers : global.headers, 
        }
        const response = await fetch(url, postData);
        const json = await response.json();
        return json; 

    } catch(error){
        //console.log(error),
        let errorObj = {
            code : 500, 
            msg : 'Network Error'
        }
        return errorObj;
    }
}

const postJSON = async(url, params)=>{
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }

    let customHeader = {...global.headers, ...headers}; 
    try {
        let postData = {
            method : 'post', 
            headers : customHeader, 
            body : JSON.stringify(params)
        }
        const response = await fetch(url, postData);
        const json = await response.json();
        return json; 

    } catch(error){
        //console.log(error),
        let errorObj = {
            code : 500, 
            msg : 'Network Error'
        }
        return errorObj;
    }
}

export { api, resCode,  executeGet, executePost, postJSON }