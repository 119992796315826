import React, { useState, useEffect} from 'react'; 
import { View, StyleSheet, FlatList,Pressable, Dimensions } from 'react-native'; 
import { Progress, SuccessPage, ErrorPage, ProgressDialog, ErrorDialog, SuccessDialog,NewsDetailDialog } from '../components/DialogComponents';
import { executeGet, executePost, api, resCode } from '../utilities/Network';
import { useSelector, useDispatch } from 'react-redux'
import { setClientConfiguration, parameters, userParams } from '../reducers/parameterSlice';
import { Label, PushButton } from '../components/FormComponents';
import { useIsMounted } from '../utilities/System';
import { Typography, Rating,  Modal , DialogContent } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';


const ContentDetail = (props)=>{
    //console.log("Content Detail");
    const isMounted = useIsMounted();
    let width = Dimensions.get('window').width - 128; 
    let height = Dimensions.get('window').height - 128 ;
    const clientParams = useSelector(parameters); 
    const [startFetch, setStartFetch] = useState(props.open); 
    const [progressState, setProgressState ] = useState({
        showProgress : false, 
        showError : false, 
        showSuccess : false, 
        message : ""
    })
    const [rateValue, setRateValue] = useState(0); 
    const [bookDetails, setBookDetails ] = useState({
        cover_file : "-"
    }); 

    useEffect(()=>{
        
        if(props.open){
            let progress = { ...progressState }
            progress.showProgress = true; 
            progress.message = "Memuat data"; 
            setProgressState(progress);
            fetchDetails();
        }
    },[props.open]);

    const fetchDetails = async()=>{
        let formData = new FormData();
        formData.append("id_konten", props.id); 

        const response = await executePost(api.detailContent, formData); 
        let progress = { ...progressState}
        if(response.code == resCode.ok){
            progress.showProgress = false; 
            setBookDetails(response.data); 
            setProgressState(progress); 
            fetchRate();
            

        } else {
            if( response.code != resCode.error){
                //TODO - Error Page
                progress.showError = true; 
                progress.message = response.msg;
                setProgressState(progress); 
            } else {
                //ANCHOR - Force Logout
                window.postMessage({command : "FORCE_LOGOUT"}, "*");

            }
        }

    }

    const onReadClick = ()=>{
        //ANCHOR - On read click
        props.navigation.navigate("Reader", bookDetails)
        props.onClose();
    }

    const onBorrowClick = ()=>{
        //ANCHOR - On Borrow Click 
        let _progressState = { ...progressState }
        _progressState.showProgress = true; 
        _progressState.message = "Meminjam Buku"; 
        setProgressState(_progressState)
        borrowRequest(); 

    }

    const borrowRequest = async()=>{
        let formData = new FormData(); 
        formData.append("id_konten", props.id)
        const response = await executePost(api.borrowBook, formData); 
        let _progressState = {...progressState }
        if(response.code == resCode.ok){
            _progressState.showProgress = false; 
            _progressState.message = "Peminjaman berhasil. Buku dapat dibaca melalui menu \"Rak Pinjam\"";
            _progressState.showSuccess = true;
            if(isMounted()){
                setProgressState(_progressState);
            }
        } else {
            _progressState.showProgress = false;  
            if(response.code != resCode.error){
                _progressState.message = response.msg; 
                _progressState.showError = true; 
                if(isMounted()){
                    setProgressState(_progressState); 
                }
            } else {
                //ANCHOR - Force Logout
                window.postMessage({command : "FORCE_LOGOUT"}, "*");
            }
        }
    }


    const fetchRate = async()=>{
        let url = api.checkRate + props.id; 
        const response = await executeGet(url); 
        if(response.code == resCode.ok){
            if(isMounted()){
                setRateValue(parseInt(response.rate));
            }
        }
    }

    const onRateClick = ()=>{

    }
    
    
    return(
        <Modal
            open={ props.open }
        >
            <DialogContent>
                <View style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: width,
                    height : height, 
                    backgroundColor: 'white',
                    boxShadow: 24,
                    borderRadius : 15, 
                }}>
                    <View style={{ paddingTop : 16 , paddingBottom : 16, flexDirection : "row", alignItems : "center", marginLeft : 16, marginRight : 16 }}>
                        <Pressable
                            onPress={ props.onClose }
                        >
                            <CloseIcon />
                        </Pressable>
                    </View>
                    {
                        progressState.showProgress && 
                        <Progress 
                            text={"Memuat data"}
                        />
                    }
                    {
                        progressState.showSuccess && 
                        <SuccessPage 
                            text={ progressState.message }
                            onClick={ props.onClose }
                        />
                    }
                    {
                        progressState.showError && 
                        <ErrorPage 
                            text={ progressState.message }
                            onClick={ props.onClose }
                        />
                    }

                    {
                        (!progressState.showProgress && !progressState.showSuccess && !progressState.showError) &&
                        <View style={{flex : 1, flexDirection : "row"}} >
                            <View
                                style={{
                                    marginLeft : 24
                                }}
                            >
                                <img
                                    src={ bookDetails.cover_file} 
                                    style={{
                                        borderRadius : 12, 
                                        height : 250,
                                        
                                    }}
                                />
                                {
                                    bookDetails.stok > 0 && 
                                    <>
                                        <PushButton
                                            variant={"contained"}
                                            text={"Baca"} 
                                            textSize={14}
                                            style={{
                                                marginTop : 12 
                                            }}
                                            onClick={ onReadClick }
                                        />
                                        <PushButton
                                            variant={"outlined"} 
                                            text={"Pinjam"}
                                            style={{marginTop : 8}} 
                                            textColor={ clientParams.warna_header }
                                            onClick={ onBorrowClick }
                                        />

                                    </>
                                }
                            </View>
                            <View style={{flex : 1, marginTop : 8, marginLeft : 16, marginRight : 16 }}>
                                <Label 
                                    size={16}
                                    text={ bookDetails.judul}
                                    weight={"600"}
                                />
                                <Pressable
                                    style={{
                                        cursor : "pointer"
                                    }}
                                    onPress={ onRateClick }
                                >
                                    <Rating
                                        value={ rateValue }
                                        readOnly
                                        size={"small"}
                                    />
                                </Pressable>
                                <Label
                                    size={14}
                                    color={ clientParams.warna_header }
                                    text={ bookDetails.penulis } 
                                    weight={"600"}
                                    style={{marginTop : 8 }}
                                />
                                <Label 
                                    size={14}
                                    text={ bookDetails.penerbit }
                                    weight={"400"}
                                    style={{marginTop : 8 }}
                                />
                                <View style={{
                                    flexDirection : "row", 
                                    alignItems : "center", 
                                    marginTop : 12, 
                                    marginBottom : 12, 
                                    padding : 4, 
                                    width : "10%", 
                                    borderRadius : 8, 
                                    borderWidth : 1, 
                                    borderColor : clientParams.warna_header,
                                    justifyContent : "center"
                                }}>
                                    <Label 
                                        size={14}
                                        color={ clientParams.warna_header}
                                        text={"Stok : " + bookDetails.stok + "/" + bookDetails.qty }
                                    />
                                </View>
                                <Typography
                                    fontFamily={"Poppins"}
                                    fontSize={14}
                                    fontWeight={"400"}
                                    component={"span"}
                                    style={{ 
                                        overflow : "scroll", 
                                        marginBottom : 12
                                    }}
                                >
                                    <div
                                        dangerouslySetInnerHTML={{ __html: bookDetails.sinopsis}}
                                    />
                                </Typography>
                            </View>
                            


                        </View>   
                    }
                </View>

            </DialogContent>
        </Modal>
    )

}

export { ContentDetail }