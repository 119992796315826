import React, { useState, useEffect, useRef } from 'react'; 
import { View, StyleSheet, FlatList,Pressable, Dimensions , TextInput} from 'react-native'; 
import { Progress, ProgressDialog, ErrorDialog, SuccessDialog,NewsDetailDialog } from '../components/DialogComponents';
import { executeGet, executePost, api, resCode, postJSON } from '../utilities/Network';
import { useSelector, useDispatch } from 'react-redux'
import { setClientConfiguration, parameters, userParams, clientAdditionalParams } from '../reducers/parameterSlice';
import { Label, PushButton, PushChip } from '../components/FormComponents';
import { useIsMounted } from '../utilities/System';
import { Paper, DialogContent, CircularProgress, Modal, ButtonBase, IconButton } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';

const DonationCart = (props)=>{
    //ANCHOR - INIT STATE
    const isMounted = useIsMounted(); 
    const clientParams = useSelector(parameters); 
    const theme = createTheme({
        palette: {
            primary: {
                main: clientParams.warna_header ,
            },
        },
    });
    const nf = new Intl.NumberFormat(); 
    
    //ANCHOR - VOUCHER REFS
    const voucherCodeRef = useRef(); 
    const [checkVoucher, setCheckVoucher] = useState(false); 
    const [validVoucher, setValidVoucher] = useState(false); 
    const [voucherCode, setVoucherCode ] = useState("-"); 
    const [defaultVoucher, setDefaultVoucher] = useState(true);
    const [discountValue, setDiscountValue] = useState("0") ; 
    const [discountSummary, setDiscountSummary] = useState({ total_diskon : 0, grand_total : 0})
    const [showTotalTransaction, setShowTotalTransaction] = useState(false); 
    
    const paymentURLRef = useRef(); 

    const [progressState, setProgressState ] = useState({
        showProgressDialog : false, 
        showProgress : false, 
        showError : false, 
        showSuccess : false, 
        message : ""
    })
    const [listItems, setListItems ] = useState([]); 
    const [loadPayment, setLoadPayment] = useState(false); 
    const [paymentTypes, setPaymentTypes] = useState([]); 
    const [selectedPaymentType, setSelectedPaymentType]= useState({ type : ""});
    

    //ANCHOR - TOTAL TRX STATE
    const [summary, setSummary] = useState({
        total : 0, 
        sub_total : 0, 
        qty : 0, 
        fee : 0,
        discountedFee : 0, 
        discount : 0, 
        discountValue : 0
    })

    
    useEffect(()=>{
        let _progressState =  { ...progressState }
        _progressState.showProgress = true; 
        _progressState.message = "Memuat Data"; 
        setProgressState(_progressState) ; 
        fetchContent(); 
    },[])

    

    //ANCHOR - FETCH CART 
    const fetchContent = async()=>{
        let formData = new FormData(); 
        let _progressState = {...progressState} ; 
        formData.append("page","1");
        formData.append("limit", "100"); 
        const response = await executePost(api.cartList, formData); 
        if(response.code == resCode.ok){
            //console.log("FETCH CART RESPONSE"); 
            //console.log(JSON.stringify(response)); 
            setProgressState(_progressState); 
            if(response.count > 0){
                let sub_total = 0; 
                let qty = response.count; 
                response.data.map((i,x)=>{
                    sub_total += parseInt(i.harga); 
                }); 
                
                setSummary({
                    total : 0, 
                    sub_total : sub_total, 
                    qty : qty, 
                    fee : 0
                })
                
                setListItems(response.data); 
                props.onUpdateCart(qty); 
                if(qty > 0 ){
                    setLoadPayment(true); 
                    fetchPaymentMethods(); 
                }  
            } else {
                props.onUpdateCart(0); 
                setPaymentTypes([]);
            }
        } else {
            if(response.code != resCode.error){
                _progressState.showError = true; 
                _progressState.message = response.msg; 
                setProgressState(_progressState)
            } else {
                //ANCHOR - FORCE LOGOUT 
                window.postMessage({command : "FORCE_LOGOUT"}, "*");
            }
        }
    }

    //ANCHOR - FETCH METHOD 
    const fetchPaymentMethods = async()=>{
        const response = await executeGet(api.paymentTypes); 
        if(response.code == resCode.ok){
            if(isMounted()){
                setLoadPayment(false); 
                setPaymentTypes(response.data); 
            }
        } 

    }


    //ANCHOR - DELETE ITEM
    const deleteItem = (item)=>{
        let formData = new FormData();
        formData.append("id_konten", item.id_konten); 
        let _progressState = {...progressState} 
        _progressState.showProgressDialog = true; 
        _progressState.message = "Menghapus Item";
        setProgressState(_progressState)
        executeDeleteItem(formData); 
        setListItems([]);
        setSummary({
            qty : 0, 
            total :0 , 
            sub_total :0, 
            fee : 0
        });
        setSelectedPaymentType({type : ""})
    }   

    //ANCHOR - REQUEST DELETE ITEM
    const executeDeleteItem = async(formData)=>{
        const response = await executePost(api.deleteCart, formData); 
        let _progressState = {...progressState}
        _progressState.showProgressDialog = false;
        if(response.code == resCode.ok){
            if(isMounted()){
                _progressState.showProgress = true; 
                setProgressState(_progressState); 
                fetchContent();
            }
        } else {
            if(response.code != resCode.error){
                if(isMounted()){
                    _progressState.showError = true; 
                    _progressState.message = response.msg;
                    setProgressState(_progressState); 
                }
            } else {
                //ANCHOR - Force Logout 
                window.postMessage({command : "FORCE_LOGOUT"}, "*");
            }
        }
    }

    //ANCHOR - RENDER ITEMS 
    const renderItem = ({item, index, separators})=>{
        return(
            <View>
                <View style={{flexDirection : 'row' , marginBottom : 8, alignItems : "center"}}>
                    <img 
                        src={item.img_file}
                        style={{borderRadius : 8, width : 120}}
                    />
                    <View style={{flex :1, marginLeft : 16, marginRight : 8 }}>
                        <Label
                            size={16}
                            text={item.judul}
                        />
                        <Label
                            size={14}
                            text={item.penulis}
                            color={"#95a5a6"}
                            style={{marginTop : 8}} 
                        />
                        <Label 
                            size={14}
                            text={item.penerbit}
                            color={"#2980b9"}
                            style={{marginTop : 4}} 
                        />
                        <Label 
                            size={14}
                            text={item.isbn}
                            style={{marginTop : 4}}
                            color={"#34495e"}
                        />
                        <Label 
                            size={16}
                            weight={"600"}
                            text={item.harga_desimal} 
                            color={"#2c3e50"}
                            style={{marginTop : 16}}
                        />
                    </View>
                    <IconButton
                        onClick={()=>{
                            deleteItem(item)
                        }}
                    >
                        <DeleteOutlineOutlinedIcon 
                            sx={{color : "#e74c3c", width : 24, height : 24}}
                        />
                    </IconButton>
                </View>
                {
                    (index != listItems.length - 1) && 
                    <View style={{ backgroundColor : "silver", height : 0.5, width : "100%",marginBottom : 8 }} />
                }
            </View>
        )
    }

    //ANCHOR - RENDER PAYMENT TYPES
    const renderPaymentTypes = ({item, index, separators})=>{
        return(
            <ButtonBase
                sx={{
                    borderRadius : 2, 
                    flex : 1, 
                    justifyContent : "left"
                }}
                onClick={()=>{
                    setSelectedPaymentType(item);
                }}
            >
                <View style={{
                    flexDirection : "row", 
                    alignItems : "center", 
                    paddingTop : 8, 
                    paddingBottom : 8 ,
                    flex :1 ,
                    borderWidth : (selectedPaymentType.type == item.type ? 1 : 0) , 
                    borderRadius : 8, 
                    borderColor : "silver"
                    }}>
                    <img 
                        src={ item.logo}
                        style={{marginLeft : 16, marginRight : 8 , width : 64, height : 64 }}
                    />  
                    <View style={{flex : 1, marginRight : 16, alignItems : "flex-start", justifyContent : "flex-start" }}>
                        <Label 
                            size={14}
                            text={item.nama}
                            weight={"400"}
                        />
                        <Label
                            size={12}
                            text={item.deskripsi} 
                            weight={"400"}
                            textAlign={"left"}
                            color={"#7f8c8d"}
                        />
                        <Label 
                            size={12}
                            weight={"400"}
                            textAlign={"left"}
                            text={ item.fee != "0" ? ("(+Rp. " + nf.format(parseInt(item.fee)) + " biaya admin)") : ("(+" + nf.format(parseInt(item.persen)) +"% biaya transaksi)")}
                        />
                    </View>
                    {
                        selectedPaymentType.type == item.type && 
                        <CheckCircleOutlinedIcon 
                            sx={{color : "#2ecc71", width : 32, height : 32, mr : 2}}
                        />
                    }

                </View>
            </ButtonBase>
        )
    }

    //ANCHOR - HANDLE CHECKOUTCLICK 
    const handleCheckoutClick = ()=>{
        let qty = summary.qty; 
        let fee = 0; 
        let sub_total = summary.sub_total; 

        //Hitung Fee
        if(selectedPaymentType.fee != "0"){
            fee = parseInt(selectedPaymentType.fee); 
        } else {
            fee = (parseInt(selectedPaymentType.persen) / 100 ) * sub_total; 
        }

        let total = sub_total + fee; 
        setSummary({
            total : total, 
            sub_total : sub_total, 
            qty : qty, 
            fee : fee 
        }) ;
        
        setShowTotalTransaction(true);

    }

    //ANCHOR - CHECK REDEEM CODE 
    const checkRedeemCode = ()=>{
        let code = voucherCodeRef.current.value; 
        code = code.replace(/\s/g, ''); 
        if(code != ""){
            setCheckVoucher(true);
            setDefaultVoucher(false); 
            validateVoucherCode(code); 
        }
        
    }
    //ANCHOR - VALIDATE VOUCHER CODE
    const validateVoucherCode = async(code)=>{
        let formData = new FormData(); 
        formData.append("kode_voucher", code); 

        const response = await executePost(api.voucherCheck, formData); 
        //console.log(JSON.stringify(response));
        if(response.code == resCode.ok){
            setVoucherCode(code); 
            let _summary = { ...summary }; 
            if(selectedPaymentType.fee == "0"){
                let percentFee = parseInt(selectedPaymentType.persen); 
                let discountedFee = parseInt(response.grand_total) * (percentFee / 100); 
                _summary.discountedFee = discountedFee; 
            } else {
                _summary.discountedFee = selectedPaymentType.fee; 
            }
            setSummary(_summary); 
            setCheckVoucher(false); 
            setDiscountValue(response.diskon); 
            setValidVoucher(true); 
            setDiscountSummary(response); 
        } else {
            setValidVoucher(false);
            setCheckVoucher(false); 

        }
    }
    //ANCHOR - EXECUTE CHECKOUT 
    const executeCheckout = ()=>{
        let parameters = {
            total : (validVoucher ? (parseInt(discountSummary.grand_total) + parseInt(summary.discountedFee) ): summary.total ), 
            sub_total : summary.sub_total, 
            diskon : (validVoucher ? discountSummary.total_diskon : 0 ), 
            kode_voucher : voucherCode, 
            id_payment : selectedPaymentType.id, 
            provider : selectedPaymentType.provider, 
            fee : (validVoucher ? summary.discountedFee : summary.fee ),
            data : []
        }
        listItems.map((i,x)=>{
            let item = {
                id_konten : i.id_konten, 
                qty : 1, 
                harga : i.harga
            }
            parameters.data.push(item)
        })
        
        setShowTotalTransaction(false); 

        let _progressState = { ...progressState} 
        _progressState.showProgressDialog = true; 
        _progressState.message = "Proses Checkout"; 
        setProgressState(_progressState); 

        requestCheckOut(parameters); 
    }

    const requestCheckOut = async(parameters)=>{
        let _progressState = { ...progressState} 
        const response = await postJSON(api.checkOutToken, parameters); 
        if(response.code == resCode.ok){
            //console.log("CHECKOUT SUCCESS"); 
            //console.log(JSON.stringify(response)); 
            paymentURLRef.current = response.url;
            _progressState.showProgressDialog = false; 
            _progressState.showSuccess = true; 
            _progressState.message = "Proses checkout berhasil!"; 
            setProgressState(_progressState); 

            setVoucherCode("-"); 
            setDefaultVoucher(true); 
            setDiscountValue(0); 
            setDiscountSummary({ total_diskon : 0, grand_total : 0})
            setSummary({
                total : 0, 
                sub_total : 0, 
                qty : 0, 
                fee : 0,
                discountedFee : 0, 
                discount : 0, 
                discountValue : 0
            }); 
            
            setListItems([]); 
            setPaymentTypes([]);
            props.onUpdateCart(0); 
        } else {
            if(response.code != resCode.error){
                _progressState.showProgressDialog = false; 
                _progressState.showError = true; 
                _progressState.message = response.msg ; 
                setProgressState(_progressState);

            } else {
                //ANCHOR - FORCE LOGOUT
                window.postMessage({command : "FORCE_LOGOUT"}, "*");
            }
        }
    }

    //ANCHOR - RENDER RETURN
    return(
        <ThemeProvider theme={ theme }>
            {
                progressState.showProgress && 
                <Progress 
                    text={ progressState.message } 
                />
            }
            {
                !progressState.showProgress && 
                <View style={{flex : 1}}>
                    <Label
                        size={18}
                        weight={"600"}
                        text={"Keranjang Donasi Anda"} 
                        style={{
                            marginLeft : 16,
                            marginTop : 16,
                            marginBottom : 16
                        }}
                        color={"#2980b9"}
                    />
                    <View 
                        style={{ width : "100%", backgroundColor : "silver", height : 0.5, marginBottom : 16}}
                    />
                    <View style={{flexDirection : "row", marginLeft : 16, marginRight : 16, flex : 1, marginBottom : 16}}>
                        {
                            //ANCHOR - LIST ITEM 
                        }
                        <Paper style={{flex : 0.6, height : "100%", marginRight : 8, borderRadius : 8, overflow : "scroll"}}>
                        {
                            listItems.length > 0 && 
                            <FlatList 
                                contentContainerStyle={{
                                    marginTop : 16,
                                    marginLeft : 16,
                                    marginRight : 16, 
                                    marginBottom : 16,
                                    
                                }}
                                keyExtractor={(i,x)=>"item_idx_" + x} 
                                data={listItems}
                                renderItem={renderItem}
                                showsVerticalScrollIndicator={false}
                            />  
                        }
                        {
                            listItems.length == 0 && 
                            <View style={{height : "100%", alignItems : "center", justifyContent : "center"}}>
                                <Label 
                                    size={18}
                                    text={"Keranjang anda kosong"}
                                    
                                />
                            </View>
                        }
                        </Paper>
                        {
                            //ANCHOR - GRAND TOTAL
                        }
                        <Paper style={{flex : 0.4, height : "100%", marginLeft: 8, borderRadius : 8, overflow : "scroll"}}>
                            <Label 
                                size={16}
                                weight={"600"}
                                text={"Pilih Metode Pembayaran"}
                                style={{marginTop : 16, marginLeft : 16}}
                            />
                            <View style={{ marginTop : 16, width : "100%", height : 0.5, backgroundColor : 'silver'}} />
                            {
                                loadPayment && 
                                <View style={{justifyContent : "center", alignItems : "center", height : "80%"}}>
                                    <CircularProgress 
                                    
                                    />
                                    <Label 
                                        size={14}
                                        color={clientParams.warna_header}
                                        text={"Memuat data"} 
                                        style={{marginTop : 8}}
                                    />
                                </View>
                            }
                            {
                                (!loadPayment && paymentTypes.length > 0) && 
                                <FlatList 
                                    keyExtractor={(i,x)=>"pay_idx_"+x}
                                    data={paymentTypes}
                                    renderItem={renderPaymentTypes}
                                    showsVerticalScrollIndicator={false}
                                    ListFooterComponent={
                                        (selectedPaymentType.type != "" ) && 
                                        <PushChip 
                                            label={"Checkout"} 
                                            color={'primary'}
                                            variant={"filled"}
                                            textWeight={"600"}
                                            sx={{ ml: 2, mr: 2, mt : 2}}
                                            onClick={ handleCheckoutClick }
                                        />
                                    }
                                />
                            }

                        </Paper>

                    </View>


                </View>
            }
            <ProgressDialog 
                open={progressState.showProgressDialog}
                message={progressState.message }
            />
            <ErrorDialog 
                open={ progressState.showError }
                message={ progressState.message } 
                label={"OK"}
                onClick={()=>{
                    let _progressState = { ...progressState } 
                    _progressState.showError = false; 
                    setProgressState(_progressState); 
                    
                }}
            />
            <SuccessDialog 
                open={ progressState.showSuccess}
                message={progressState.message }
                label={"Cara Pembayaran"}
                onClick={()=>{
                    let _progressState = {...progressState}
                    _progressState.showSuccess = false; 
                    setProgressState(_progressState); 
                    window.electron.sendMessage("OPEN_URL", paymentURLRef.current); 

                }}
            />
            {
                //ANCHOR - TOTAL TRANSACTION MODAL
            }
            <Modal
                open={ showTotalTransaction }
            >
                <DialogContent>
                    <View style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 450,
                            height : 550, 
                            backgroundColor: 'white',
                            boxShadow: 24,
                            borderRadius : 15, 
                    }}>

                        <Label
                            size={16}
                            weight={"600"}
                            text={"Rincian Transaksi"}
                            style={{marginLeft : 16, marginTop : 16, marginBottom : 16 }}
                        />
                        <View style={{ backgroundColor : "silver", height : 0.5, width : "100%", marginBottom : 16}} /> 
                        <View style={{flexDirection : "row", alignItems : "center", marginLeft : 16, marginRight : 16}}>
                            <Label 
                                size={14}
                                text={"Sub Total"}
                                style={{flex : 0.3}}
                                
                            />
                            <Label 
                                size={14}
                                weight={"600"}
                                style={{flex : 0.7}}
                                text={"Rp. " + nf.format(summary.sub_total)}
                                textAlign={"end"} 
                            />
                        </View>
                        {
                            //ANCHOR - VALID DISKON
                            validVoucher && 
                            <View style={{flexDirection : "row", alignItems : "center", marginLeft : 16, marginTop : 4, marginRight : 16}}>
                                <Label 
                                    size={14}
                                    text={"Diskon"}
                                    style={{flex : 0.3}}
                                    
                                />
                                <Label 
                                    size={14}
                                    weight={"600"}
                                    style={{flex : 0.7}}
                                    text={"- Rp. " + nf.format(discountSummary.total_diskon)}
                                    textAlign={"end"} 
                                />
                            </View>
                        }
                        <View style={{flexDirection : "row", alignItems : "center", marginTop : 4, marginLeft : 16, marginRight : 16}}>
                            <Label 
                                size={14}
                                text={"Biaya Transaksi"}
                                style={{flex : 0.3}}
                            />
                            <Label 
                                size={14}
                                weight={"600"}
                                style={{flex : 0.7}}
                                text={(!validVoucher ? "Rp. " + nf.format(summary.fee) : "Rp. " + nf.format(summary.discountedFee))} 
                                textAlign={"end"}
                            />
                        </View>
                        <View style={{ backgroundColor : "silver", height : 0.5, width : "100%", marginTop : 16, marginBottom : 16 }}/>
                        <View style={{flexDirection : "row", alignItems : "center", marginLeft : 16, marginRight : 16}}>
                            <Label 
                                size={16}
                                text={"Total"}
                                color={"#2980b9"}
                                style={{flex : 0.3}}
                            />
                            <Label 
                                size={16}
                                weight={"600"}
                                style={{flex : 0.7}}
                                color={"#2980b9"}
                                text={(!validVoucher ? "Rp. " + nf.format(summary.total) : "Rp. " + nf.format(parseInt(discountSummary.grand_total) + parseInt(summary.discountedFee)) )} 
                                textAlign={"end"}
                            />
                        </View>
                        <View style={{ backgroundColor : "silver", height : 0.5, width : "100%", marginTop : 16 }}/>
                        <Label
                            size={16}
                            weight={"600"}
                            text={"Metode pembayaran"}
                            style={{marginLeft : 16, marginTop : 16, marginBottom : 16 }}
                        />
                        <View style={{flexDirection : "row", alignItems : "center", marginLeft : 16, marginRight : 16}}>
                            <img 
                                src={selectedPaymentType.logo}
                                style={{ width : 64, height : 64}}
                            />
                            <View style={{ flex : 1, marginLeft : 12, }}>
                                <Label 
                                    size={14}
                                    weight={"600"}
                                    text={selectedPaymentType.nama} 
                                />
                                <Label 
                                    size={14}
                                    text={selectedPaymentType.deskripsi}
                                />
                            </View>
                        </View>
                        {
                            //ANCHOR - DISCOUNT CODE
                        }
                        <Label
                            size={16}
                            weight={"600"}
                            text={"Punya Kode Diskon?"}
                            style={{marginLeft : 16, marginTop : 16, marginBottom : 16 }}
                        />
                        <View style={{flexDirection : "row", alignItems : "center", marginLeft : 16, marginRight : 16}}>
                            {
                                (!checkVoucher && defaultVoucher) &&
                                <>
                                    <TextInput 
                                        ref={ voucherCodeRef }
                                        autoCapitalize={"characters"}
                                        style={{
                                            padding : 8 , 
                                            fontFamily : "Poppins", 
                                            borderRadius : 8, 
                                            borderWidth : 0.5,
                                            outlineWidth : 0.5, 
                                            outlineColor : clientParams.warna_header,
                                            flex : 1
                                        }}
                                        placeholder={"Masukkan kode diskon"}
                                    />
                                    <PushChip 
                                        variant={"outlined"} 
                                        label={"Check"} 
                                        color={"primary"}
                                        style={{marginLeft : 12 }}
                                        onClick={ checkRedeemCode }
                                    />
                                </>
                            }
                            {
                                checkVoucher && 
                                <View style={{flexDirection : "row", alignItems : "center"}}>
                                    <CircularProgress />
                                    <Label 
                                        size={12}
                                        text={"Validasi kode voucher"} 
                                        style={{ marginLeft : 12 }}
                                    />
                                </View>
                            }
                            {
                                //ANCHOR - DISKON TIDAK VALID
                                (!checkVoucher && !defaultVoucher && !validVoucher) && 
                                <View style={{flexDirection : "row", alignItems : "center", width : "100%"}}>
                                    <WarningAmberOutlinedIcon sx={{color : "#e74c3c", width : 28, height : 28 }}/>
                                    <Label 
                                        size={12}
                                        text={"Kode voucher tidak ditemukan/sudah tidak berlaku"}
                                        style={{marginLeft : 12,marginRight : 12, flex : 1}}
                                    />
                                    <IconButton
                                        onClick={()=>{
                                            setVoucherCode("-"); 
                                            setDefaultVoucher(true); 
                                            setDiscountValue(0); 
                                            setDiscountSummary({ total_diskon : 0, grand_total : 0})
                                        }}
                                    >
                                        <CloseOutlinedIcon 
                                            sx={{width : 24, height : 24}}
                                        />
                                    </IconButton>

                                </View>
                            }
                            {
                                //ANCHOR - DISKON VALID
                                (!checkVoucher && !defaultVoucher && validVoucher) && 
                                <View style={{flexDirection : "row", alignItems : "center", width : "100%"}}>
                                    <DoneOutlinedIcon sx={{color : "#6ab04c", width : 28, height : 28 }}/>
                                    <Label 
                                        size={12}
                                        text={"Anda mendapatkan diskon sebesar " + discountValue + "%"}
                                        style={{marginLeft : 12,marginRight : 12, flex : 1}}
                                    />
                                    <IconButton
                                        onClick={()=>{
                                            setVoucherCode("-"); 
                                            setDefaultVoucher(true); 
                                            setDiscountValue(0); 
                                            setValidVoucher(false);  
                                            setDiscountSummary({ total_diskon : 0, grand_total : 0})
                                        }}
                                    >
                                        <DeleteOutlineOutlinedIcon 
                                            sx={{width : 24, height : 24}}
                                        />
                                    </IconButton>

                                </View>

                            }
                            

                        </View>

                        <View style={{position: "absolute", bottom : 16 , left : 16, right : 16, flexDirection : "row-reverse", alignItems : "center"}}>
                            <PushButton 
                                variant={"contained"}
                                text={"Lakukan Pembayaran"}
                                style={{borderRadius : 12}}
                                onClick={ executeCheckout }
                            />
                            <PushButton
                                variant={"outlined"}
                                text={"Batal"}
                                style={{marginRight : 12, borderRadius : 12}}
                                onClick={()=>{
                                    setShowTotalTransaction(false);
                                    setDiscountSummary({ total_diskon : 0, grand_total : 0});
                                    setValidVoucher(false); 
                                    setDefaultVoucher(true);
                                    setDiscountValue(0); 
                                    setVoucherCode("-"); 
                                }}
                            />
                        
                        </View>
                    </View>

                </DialogContent>
            </Modal>
            
        </ThemeProvider>

    )

}

export{ DonationCart }