import { createSlice } from '@reduxjs/toolkit';

export const parameterSlice = createSlice({
    name : "parameter", 
    initialState : {
        value : {},
        headers : {},
        users : {},
        mitra : {}
    },
    reducers : {
        setClientConfiguration : (state, action)=>{
            state.value = action.payload
        },
        setHeaderConfiguration : (state, action)=>{
            state.headers = action.payload; 
        },
        setUserParams : (state, action)=>{
            state.users = action.payload;
        },
        setClientParams : (state, action)=>{
            state.mitra = action.payload; 
        }
    }
})

const clientParams = (state)=>state.parameter.value; 
const headerParams = (state)=>state.parameter.headers;
const userParams = (state)=>state.parameter.users;
const clientAdditionalParams = (state)=>state.parameter.mitra; 
export { clientParams, headerParams, userParams, clientAdditionalParams }; 
export const { setClientConfiguration, setHeaderConfiguration, setUserParams,setClientParams } = parameterSlice.actions;   
export const parameters = (state)=>state.parameter.value;

export default parameterSlice.reducer;