import React, { useState, useRef } from 'react'; 
import { View , StyleSheet} from 'react-native';
import { Typography, IconButton, InputBase,Button, Radio, InputLabel,Chip, 
    RadioGroup, FormControlLabel, FormGroup, FormControl, Checkbox, Select, MenuItem } from '@mui/material'; 
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux'
import { parameters } from '../reducers/parameterSlice';

import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import { CheckBox } from 'react-native-web';

const Label = React.forwardRef((props, innerRef)=>{
    return(
        <Typography
            ref={ innerRef }
            fontFamily="Poppins"
            fontWeight={ props.weight } 
            fontSize={ props.size } 
            style={ props.style }
            color={ props.color }
            textAlign={ props.textAlign }
            align={ props.align }
            onClick={ props.onClick }
        >
            { props.text }
        </Typography>

    )
}); 


const TextField = React.forwardRef((props, innerRef)=>{
    const [value, setValue ] = useState("");
    const onChangeText = (e)=>{
        props.onChange(e.target.value);

    }
    return(
        <View
            style={{
                marginTop : props.marginTop, 
                marginBottom : props.marginBottom, 
                marginLeft : props.marginLeft, 
                marginRight : props.marginRight,
                maxWidth : props.maxWidth,
                maxHeight : props.maxHeight

            }}
        >
        {
            props.withLabel && 
            <Label
                weight={props.labelWeight}
                size={props.labelSize}
                style={ props.labelStyle }
                align={ props.labelAlign }
                text={props.labelText}
            />
        }
            <View style={{
                width : props.width, 
                borderColor : props.borderColor, 
                flexDirection : "row", 
                alignItems : "center",
                borderRadius : 8, 
                borderWidth : 0.3, 
                backgroundColor : props.backgroundColor,
                paddingLeft : 16, 
                paddingTop : (props.withIcon ? 2 : 8 ), 
                paddingBottom : ( props.withIcon ? 2 : 8 ), 
                paddingRight : (props.withIcon ? 4 : 16 ) 
            }}>
                <InputBase 
                    inputRef={ innerRef }
                    style={{
                        padding : props.padding,
                        fontFamily : "Poppins", 
                        fontSize : props.fontSize,
                        flex : 1
                    }}
                    readOnly={ props.readOnly }
                    defaultValue={ props.defaultValue }
                    value={ props.value }
                    placeholder={ props.placeholder}
                    type={ props.type }
                    onChange={ onChangeText }
                    onKeyPress={ props.onKeyPress }
                />
                {
                    props.withIcon && 
                    <IconButton style={{marginRight : 8 }} onClick={ props.onIconClick }>
                    { props.icon }
                    </IconButton>
                }
            </View>
            {
                props.error && 
                <View style={{flexDirection : "row-reverse", marginTop : 4 }}>
                    <Label
                        size={12}
                        color={"#d63031"}
                        text={ props.errorText }
                    />
                </View>
            }
        </View>
    )
})

TextField.defaultProps = {
    withLabel : true, 
    labelWeight : "400", 
    labelSize : 14, 
    width : "100%", 
    borderColor : "#636e72",
    backgroundColor : "white",
    fontSize : 14 ,
    marginTop : 0,
    marginBottom : 0, 
    marginLeft : 0, 
    marginRight : 0,
    withIcon : false,
    labelStyle : {
        marginBottom : 8
    },
    type : "text",
    error : false
}


const PushButton = (props)=>{
    const clientParams = useSelector(parameters);
  
    const theme = createTheme({
        palette: {
            primary: {
                main: clientParams.warna_header ,
            },
        },
    });

    return(
        <ThemeProvider theme={ theme }>
            <Button
                variant={ props.variant }
                style={ props.style }
                size={ props.size }
                onClick={ props.onClick }
                disabled={ props.disabled }
            >
                <Label
                    size={ props.textSize }
                    text={ props.text }
                    color={ props.textColor }
                    weight={ props.textWeight }
                    style={ props.textStyle }
                />
            </Button>
        </ThemeProvider>
    )
}

PushButton.defaultProps = {
    variant : "contained", 
    textSize : 14, 
    textWeight : "600",
    size : "medium"
}

const Options = (props)=>{
    const clientParams = useSelector(parameters);
    const theme = createTheme({
        palette: {
            primary: {
                main: clientParams.warna_header ,
            },
        },
    });

    return(
        <ThemeProvider theme={ theme }>
             <View style={ props.style }>
                <Label 
                    weight={"400"}
                    size={14}
                    text={ props.label }
                />
                <RadioGroup 
                    row
                    onChange={(event)=>{
                        props.onChange(event.target.value)
                    }}
                >
                    <FormControlLabel value={ props.valueOne } control={<Radio checked={ props.checkedValue == props.valueOne }/>} label={ props.labelOne } />
                    <FormControlLabel value={ props.valueTwo } control={<Radio checked={ props.checkedValue == props.valueTwo }/>} label={ props.labelTwo } />
                </RadioGroup>
            </View>
        </ThemeProvider>
    )
}

const Checklist = (props)=>{
    const clientParams = useSelector(parameters);
   
    const theme = createTheme({
        palette: {
            primary: {
                main: clientParams.warna_header ,
            },
        },
    });

 

    return(
      <ThemeProvider theme={ theme }>
        <View style={ props.style }>
            <View style={{ flexDirection : "row", alignItems : "center"}}>
                <Checkbox
                    onChange={(e)=>{ props.onChecked(e.target.checked )}}
                />
                <Label
                    size={12}
                    text={props.label}
                    color={ props.labelColor }
                    style={ props.labelStyle }
                    onClick={ props.onLabelClick }
                />
            </View>
        </View>

      </ThemeProvider>

    )
}

const ComboBox = React.forwardRef((props, innerRef )=>{
    const clientParams = useSelector(parameters);
    const theme = createTheme({
        palette: {
            primary: {
                main: clientParams.warna_header ,
            },
        },
    });

    return(
        <ThemeProvider theme={ theme }>
            <View 
                style={ props.containerStyle }>
                <Label
                    size={ props.labelSize } 
                    text={ props.label }
                    weight={ props.labelWeight }
                    style={{
                        marginBottom : 8
                    }}
                />
                <Select
                    style={ props.style }
                    defaultValue={ props.defaultValue }
                    value={ props.value }
                    sx={{
                        fontFamily : "Poppins", 
                        fontWeight : "400",
                        fontSize : 12
                    }}
                    onChange={(e)=>{ props.onChange(e.target.value )}}
                >   
                    {
                        props.options.map((i,x)=>(
                            <MenuItem 
                                key={"idx_"+x} 
                                value={i.id}
                                sx={{
                                    fontFamily : "Poppins",
                                    fontWeight : "400",
                                    fontSize : 12
                                }}
                                >{ i.label}
                            </MenuItem>
                        ))
                    }
                </Select>
                {
                    props.error && 
                    <View style={{ flexDirection : "row-reverse", marginTop : 4 }}>
                        <Label
                            size={12}
                            color={"#d63031"}
                            text={ props.errorText }
                        />
                    </View>
                }
            </View>
        </ThemeProvider>
    )

})

const PushChip = (props)=>{
    const clientParams = useSelector(parameters);
    const theme = createTheme({
        palette: {
            primary: {
                main: clientParams.warna_header ,
            },
        },
        components: {
            MuiChip : {
                styleOverrides: {
                    label : {
                        fontSize : props.textSize,
                        fontFamily : "Poppins",
                        fontWeight : props.textWeight, 
                        paddingLeft : props.textPaddingLeft, 
                        paddingRight : props.textPaddingRight, 
                        paddingTop : props.textPaddingTop, 
                        paddingBottom : props.textPaddingBottom
                    }
                }
            }
        }
    });

    return( 
        <ThemeProvider theme={ theme }> 
            <Chip 
                label={props.label}
                variant={ props.variant}
                onClick={ props.onClick }
                style={ props.style }
                color={props.color}
                size={props.size}
                sx={ props.sx}
            />
        </ThemeProvider>
    )

}
export { Label, TextField, PushButton,Options, Checklist, ComboBox, PushChip }